import {
  LOADING_RECOMMENDATION,
  STOP_LOADING_RECOMMENDATION,
  RECOMMENDATION_ERROR,
  SET_RECOMMENDATIONS,
  SET_RECOMMENDATION,
  DELETE_RECOMMENDATION,
  CLEAR_ERRORS_RECOMMENDATION,
  INITIAL_STATE_RECOMMENDATION,
  INITIAL_ERROR_RECOMMENDATION,
  RECOMMENDATION_SUCCESS,
  INITIAL_SUCCESS_RECOMMENDATION
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all recommendations belong to customer
const initialState = {
  recommendations: [],
  recommendation: {},
  loading: false,
  errors: null,
  success: null
}

const RecommendationReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_RECOMMENDATION:
      return { ...state, loading: true }
    case STOP_LOADING_RECOMMENDATION:
      return { ...state, loading: false }
    case RECOMMENDATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_RECOMMENDATIONS:
      return { ...state, recommendations: action.payload, loading: false }
    case SET_RECOMMENDATION:
      return { ...state, recommendation: action.payload, loading: false }
    case DELETE_RECOMMENDATION:
      return {
        ...state,
        recommendations: state.recommendations.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_RECOMMENDATION:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_RECOMMENDATION:
      return { ...state, loading: false, errors: null }
    case RECOMMENDATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_RECOMMENDATION:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_RECOMMENDATION:
      return initialState
    default:
      return state
  }
}

export default RecommendationReducer
