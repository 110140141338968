import React from "react"
import { Link } from "react-router-dom"
// MUI Stuff
// import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
import Collapse from "@mui/material/Collapse"
// Icons
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined"
// import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined"
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import FilePresentIcon from "@mui/icons-material/FilePresent"
import WorkIcon from "@mui/icons-material/Work"
import EngineeringIcon from "@mui/icons-material/Engineering"
import GarageIcon from "@mui/icons-material/Garage"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import StarBorder from "@mui/icons-material/StarBorder"
import StarIcon from "@mui/icons-material/Star"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"
import VerifiedIcon from "@mui/icons-material/Verified"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate"
// Redux Stuff
import { connect } from "react-redux"

function MainListItems(props) {
  const { userData } = props
  const [openTicket, setOpenTicket] = React.useState(true)
  // console.log("User details---", userData)

  const handleClickTicket = () => {
    setOpenTicket(!openTicket)
  }

  const markupListMenuFinanceOnly =
    userData?.roles === "employee" && userData?.typeEmployee === "financer" ? (
      <>
        <ListItemButton component={Link} to="/banks">
          <ListItemIcon>
            <AccountBalanceIcon />
          </ListItemIcon>
          <ListItemText primary="List Banks" />
        </ListItemButton>
        <ListItemButton component={Link} to="/claims/payments-request">
          <ListItemIcon>
            <AccountBalanceWalletIcon />
          </ListItemIcon>
          <ListItemText primary="Payments request" />
        </ListItemButton>
      </>
    ) : null
  // Show when user is supervisor only
  const markupListMenuEmployeeTracker =
    userData?.roles === "employee" ? (
      <ListItemButton component={Link} to="/supplementary/claims">
        <ListItemIcon>
          <ControlPointDuplicateIcon />
        </ListItemIcon>
        <ListItemText primary="Supplementary" />
      </ListItemButton>
    ) : null

  // Show when user is supervisor only
  const markupListMenuSupervisorOnly =
    userData?.roles === "employee" && userData?.isClaimApprover ? (
      <ListItemButton component={Link} to="/claims/waiting-approval">
        <ListItemIcon>
          <VerifiedIcon />
        </ListItemIcon>
        <ListItemText primary="Approvals" />
      </ListItemButton>
    ) : null

  //   Show when is not a intermediary user /*Verify if user right exist before show menu*/
  const markupListItemForIntermediaryRoles =
    userData?.roles === "intermediary" ||
    (userData?.roles === "employee" && userData?.typeEmployee === "financer")
      ? null
      : userData?.Right && (
          <React.Fragment>
            {userData?.Right?.claimViewNotAssign && (
              <>
                <ListItemButton onClick={handleClickTicket}>
                  <ListItemIcon>
                    <FilePresentIcon />
                  </ListItemIcon>
                  <ListItemText primary="Tickets" />
                  {openTicket ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={openTicket} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/all-assign-claims">
                      <ListItemIcon>
                        <StarIcon />
                      </ListItemIcon>
                      <ListItemText primary="Assign" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} component={Link} to="/all-notassign-claims">
                      <ListItemIcon>
                        <StarBorder />
                      </ListItemIcon>
                      <ListItemText primary="Not Assign" />
                    </ListItemButton>
                  </List>
                </Collapse>
              </>
            )}
            {userData?.Right?.userView && (
              <ListItemButton component={Link} to="/users">
                <ListItemIcon>
                  <GroupOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="List Users" />
              </ListItemButton>
            )}
            {userData?.Right?.brancheView && (
              <ListItemButton component={Link} to="/branches">
                <ListItemIcon>
                  <ApartmentOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary="List Branches" />
              </ListItemButton>
            )}
            {userData?.Right?.intermediarieView && (
              <ListItemButton component={Link} to="/intermediaries">
                <ListItemIcon>
                  <HomeWorkIcon />
                </ListItemIcon>
                <ListItemText primary="List of intermediaries" />
              </ListItemButton>
            )}
            {userData?.Right?.workgroupView && (
              <ListItemButton component={Link} to="/workgroups">
                <ListItemIcon>
                  <WorkIcon />
                </ListItemIcon>
                <ListItemText primary="List Workgroups" />
              </ListItemButton>
            )}
            {userData?.Right?.consultantView && (
              <ListItemButton component={Link} to="/consultants">
                <ListItemIcon>
                  <EngineeringIcon />
                </ListItemIcon>
                <ListItemText primary="List of Consultants" />
              </ListItemButton>
            )}
            <ListItemButton component={Link} to="/garages">
              <ListItemIcon>
                <GarageIcon />
              </ListItemIcon>
              <ListItemText primary="List of Garages" />
            </ListItemButton>
          </React.Fragment>
        )

  return (
    <>
      <ListItemButton component={Link} to="/">
        <ListItemIcon>
          <HomeOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton component={Link} to="/reports">
        <ListItemIcon>
          <AssessmentOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItemButton>
      <ListItemButton component={Link} to="/claimstatus">
        <ListItemIcon>
          <SearchOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Search" />
      </ListItemButton>

      {/* Check base on roles */}
      {markupListMenuSupervisorOnly}
      {markupListMenuEmployeeTracker}
      {markupListMenuFinanceOnly}
      {markupListItemForIntermediaryRoles}
    </>
  )
}

const mapStateToProps = (state) => ({
  userData: state.user.credentials
})

export default connect(mapStateToProps)(MainListItems)
