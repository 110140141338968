import {
  LOADING_RIGHT,
  STOP_LOADING_RIGHT,
  RIGHT_ERROR,
  SET_RIGHTS,
  SET_RIGHT,
  DELETE_RIGHT,
  UPDATE_RIGHT,
  CLEAR_ERRORS_RIGHT,
  INITIAL_STATE_RIGHT,
  INITIAL_ERROR_RIGHT
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  rights: [],
  right: {},
  loading: false,
  errors: null
}

const RightReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_RIGHT:
      return { ...state, loading: true }
    case STOP_LOADING_RIGHT:
      return { ...state, loading: false }
    case RIGHT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_RIGHTS:
      return { ...state, rights: action.payload, loading: false }
    case SET_RIGHT:
      return { ...state, right: action.payload, loading: false }
    case UPDATE_RIGHT:
      return { ...state, loading: false }
    case DELETE_RIGHT:
      return {
        ...state,
        rights: state.rights.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_RIGHT:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_RIGHT:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_RIGHT:
      return initialState
    default:
      return state
  }
}

export default RightReducer
