import {
  LOADING_FINANCE_MEETCRITERIA,
  STOP_LOADING_FINANCE_MEETCRITERIA,
  FINANCE_MEETCRITERIA_ERROR,
  SET_FINANCE_MEETCRITERIAS,
  SET_FINANCE_MEETCRITERIA,
  DELETE_FINANCE_MEETCRITERIA,
  CLEAR_ERRORS_FINANCE_MEETCRITERIA,
  INITIAL_STATE_FINANCE_MEETCRITERIA,
  INITIAL_ERROR_FINANCE_MEETCRITERIA,
  FINANCE_MEETCRITERIA_SUCCESS,
  INITIAL_SUCCESS_FINANCE_MEETCRITERIA
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all meetcriterias belong to customer
const initialState = {
  meetcriterias: [],
  meetcriteria: {},
  loading: false,
  errors: null,
  success: null
}

const FinanceMeetCriteriaReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FINANCE_MEETCRITERIA:
      return { ...state, loading: true }
    case STOP_LOADING_FINANCE_MEETCRITERIA:
      return { ...state, loading: false }
    case FINANCE_MEETCRITERIA_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_FINANCE_MEETCRITERIAS:
      return { ...state, meetcriterias: action.payload, loading: false }
    case SET_FINANCE_MEETCRITERIA:
      return { ...state, meetcriteria: action.payload, loading: false }
    case DELETE_FINANCE_MEETCRITERIA:
      return {
        ...state,
        meetcriterias: state.meetcriterias.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_FINANCE_MEETCRITERIA:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_FINANCE_MEETCRITERIA:
      return { ...state, loading: false, errors: null }
    case FINANCE_MEETCRITERIA_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_FINANCE_MEETCRITERIA:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_FINANCE_MEETCRITERIA:
      return initialState
    default:
      return state
  }
}

export default FinanceMeetCriteriaReducer
