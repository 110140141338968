import React from "react"
// MUI Stuff
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://www.hollard.com.gh/">
        Hollard
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

export default Copyright
