import {
  LOADING_BRANCHE,
  STOP_LOADING_BRANCHE,
  BRANCHE_ERROR,
  SET_BRANCHES,
  SET_BRANCHE,
  DELETE_BRANCHE,
  CLEAR_ERRORS_BRANCHE,
  INITIAL_STATE_BRANCHE,
  INITIAL_ERROR_BRANCHE
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  branches: [],
  branche: {},
  loading: false,
  errors: null
}

const BrancheReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_BRANCHE:
      return { ...state, loading: true }
    case STOP_LOADING_BRANCHE:
      return { ...state, loading: false }
    case BRANCHE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_BRANCHES:
      return { ...state, branches: action.payload, loading: false }
    case SET_BRANCHE:
      return { ...state, branche: action.payload, loading: false }
    case DELETE_BRANCHE:
      return {
        ...state,
        branches: state.branches.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_BRANCHE:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_BRANCHE:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_BRANCHE:
      return initialState
    default:
      return state
  }
}

export default BrancheReducer
