import React from "react"
// MUI Stuff
// import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select from "@mui/material/Select"
// Redux Stuff
import { connect } from "react-redux"
import { store } from "../../redux/Store"
import { SET_TYPE_POLICY_USER } from "../../redux/Types"

function MenuTypeOfPolicy(props) {
  const { credentialsTypeOfPolicy } = props
  // React state
  const [typeOfPolicy, setTypeOfPolicy] = React.useState(credentialsTypeOfPolicy)
  //   console.log("Type of policy---", credentialsTypeOfPolicy)

  const handleChange = (event) => {
    setTypeOfPolicy(event.target.value)
    // Update store type of policy
    store.dispatch({ type: SET_TYPE_POLICY_USER, payload: typeOfPolicy })
  }

  return (
    <FormControl variant="standard">
      <Select
        style={{ color: "white" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={typeOfPolicy}
        label="Choose Type of policy types"
        onChange={handleChange}
      >
        <MenuItem value={"motor"}>Motor</MenuItem>
        <MenuItem disabled value={"homeAndContentInsurance"}>
          Home and Content Insurance
        </MenuItem>
        <MenuItem disabled value={"personalAccident"}>
          Personal Accident
        </MenuItem>
        <MenuItem disabled value={"travelInsurance"}>
          Travel Insurance
        </MenuItem>
      </Select>
    </FormControl>
  )
}

const mapStateToProps = (state) => ({
  credentialsTypeOfPolicy: state.user.typeOfPolicy
})

export default connect(mapStateToProps)(MenuTypeOfPolicy)
