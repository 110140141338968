import {
  SET_USER,
  SET_USERS,
  SET_USER_DETAILS,
  SET_USER_DETAILS_CREDENTIALS,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  USER_ERROR,
  STOP_LOGIN_REQUEST,
  LOADING_USER,
  STOP_LOADING_USER,
  SIGNOUT_SUCCESS,
  SIGNOUT_ERROR,
  INITIAL_ERROR_USER,
  CLEAR_ERRORS_USER,
  USER_SUCCESS,
  INITIAL_SUCCESS_USER,
  CLEAR_SUCCESS_USER,
  SET_TYPE_POLICY_USER
} from "../Types"

const initialState = {
  isAuthenticated: false,
  token: null,
  users: [],
  user: {},
  typeOfPolicy: "motor",
  credentials: {},
  loading: false,
  loginRequest: false,
  errors: null,
  success: null
}

const UserReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: true
      }
    case STOP_LOGIN_REQUEST:
      return {
        ...state,
        loginRequest: false
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loginRequest: false,
        errors: action.payload
      }
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case LOADING_USER:
      return {
        ...state,
        loading: true
      }
    case STOP_LOADING_USER:
      return {
        ...state,
        loading: false
      }
    case SET_USER_DETAILS:
      return {
        ...state,
        isAuthenticated: true,
        loginRequest: false,
        token: action.payload.token,
        credentials: action.payload.user,
        loading: false,
        errors: null
      }
    case SET_USER_DETAILS_CREDENTIALS:
      return {
        ...state,
        credentials: { ...state.credentials, ...action.payload }
      }
    case SIGNOUT_ERROR:
      return {
        ...state,
        loading: false,
        loginRequest: false,
        errors: action.payload
      }
    case SET_USERS:
      return { ...state, users: action.payload, loading: false }
    case SET_USER:
      return { ...state, user: action.payload, loading: false }
    case INITIAL_ERROR_USER:
      return {
        ...state,
        errors: null
      }
    case SET_TYPE_POLICY_USER:
      return {
        ...state,
        typeOfPolicy: action.payload
      }
    case CLEAR_ERRORS_USER:
      return {
        ...state,
        loading: false,
        loginRequest: false,
        errors: null
      }
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_USER:
      return {
        ...state,
        success: null
      }
    case CLEAR_SUCCESS_USER:
      return {
        ...state,
        loading: false,
        loginRequest: false,
        success: null
      }
    case SIGNOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default UserReducer
