import { useNavigate } from "react-router-dom"
import axios from "../api/axios"
// Redux Stuff
import { store } from "../redux/Store"
import { SET_USER_DETAILS, CLEAR_ERRORS_USER } from "../redux/Types"
import useLogout from "./useLogout"

const useRefreshToken = () => {
  const logout = useLogout()
  const navigate = useNavigate()

  const refresh = async () => {
    try {
      // Launch response
      const response = await axios.get("/auth/refresh", {
        withCredentials: true
      })
      // Get respon API
      const token = response.data.token
      const userDetails = response.data.data
      // console.log("useRefresh Token----", token)
      // console.log("useRefresh User details----", userDetails)

      store.dispatch({
        type: SET_USER_DETAILS,
        payload: { user: userDetails, token }
      })
      // Clean errors user
      store.dispatch({ type: CLEAR_ERRORS_USER })

      return response.data.token
    } catch (error) {
      // console.log("Status refresh----", error?.response?.status)
      if (error?.response?.status === 401) {
        await logout()
        navigate("/signin")
      }
      return
    }
  }
  return refresh
}

export default useRefreshToken
