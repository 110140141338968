import {
  LOADING_FINANCE,
  STOP_LOADING_FINANCE,
  FINANCE_ERROR,
  SET_FINANCES,
  SET_FINANCE,
  DELETE_FINANCE,
  CLEAR_ERRORS_FINANCE,
  INITIAL_STATE_FINANCE,
  INITIAL_ERROR_FINANCE,
  FINANCE_SUCCESS,
  INITIAL_SUCCESS_FINANCE
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all finances belong to customer
const initialState = {
  finances: [],
  finance: {},
  loading: false,
  errors: null,
  success: null
}

const FinanceReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FINANCE:
      return { ...state, loading: true }
    case STOP_LOADING_FINANCE:
      return { ...state, loading: false }
    case FINANCE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_FINANCES:
      return { ...state, finances: action.payload, loading: false }
    case SET_FINANCE:
      return { ...state, finance: action.payload, loading: false }
    case DELETE_FINANCE:
      return {
        ...state,
        finances: state.finances.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_FINANCE:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_FINANCE:
      return { ...state, loading: false, errors: null }
    case FINANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_FINANCE:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_FINANCE:
      return initialState
    default:
      return state
  }
}

export default FinanceReducer
