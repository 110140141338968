import {
  LOADING_CONSULTANT,
  STOP_LOADING_CONSULTANT,
  CONSULTANT_ERROR,
  SET_CONSULTANTS,
  SET_CONSULTANT,
  DELETE_CONSULTANT,
  CLEAR_ERRORS_CONSULTANT,
  INITIAL_STATE_CONSULTANT,
  INITIAL_ERROR_CONSULTANT
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  consultants: [],
  consultant: {},
  loading: false,
  errors: null
}

const ConsultantReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CONSULTANT:
      return { ...state, loading: true }
    case STOP_LOADING_CONSULTANT:
      return { ...state, loading: false }
    case CONSULTANT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_CONSULTANTS:
      return { ...state, consultants: action.payload, loading: false }
    case SET_CONSULTANT:
      return { ...state, consultant: action.payload, loading: false }
    case DELETE_CONSULTANT:
      return {
        ...state,
        consultants: state.consultants.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_CONSULTANT:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_CONSULTANT:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_CONSULTANT:
      return initialState
    default:
      return state
  }
}

export default ConsultantReducer
