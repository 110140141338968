import axios from "../api/axios"
// import useAuth from "./useAuth"
// Redux Stuff
import { store } from "../redux/Store"
import {
  LOGIN_REQUEST,
  SIGNOUT_SUCCESS,
  INITIAL_STATE_INTERMEDIARIE,
  INITIAL_STATE_BRANCHE,
  INITIAL_STATE_CLAIM,
  INITIAL_STATE_CORPORATE,
  INITIAL_STATE_WORKGROUP,
  INITIAL_STATE_PROCESSING,
  INITIAL_STATE_ANALYTICS,
  INITIAL_STATE_RIGHT,
  INITIAL_STATE_VERIFICATION,
  INITIAL_STATE_INVESTIGATION,
  INITIAL_STATE_ADJUSTMENT,
  INITIAL_STATE_APPROVAL,
  INITIAL_STATE_DISCHARGE_VOUCHER,
  INITIAL_STATE_RECOMMENDATION,
  INITIAL_STATE_FINANCE,
  INITIAL_STATE_CONSULTANT,
  INITIAL_STATE_MESSAGE
} from "../redux/Types"

const useLogout = () => {
  //   const { setAuth } = useAuth()

  const logout = async () => {
    store.dispatch({ type: LOGIN_REQUEST })
    store.dispatch({ type: INITIAL_STATE_BRANCHE })
    store.dispatch({ type: INITIAL_STATE_CLAIM })
    store.dispatch({ type: INITIAL_STATE_CORPORATE })
    store.dispatch({ type: INITIAL_STATE_INTERMEDIARIE })
    store.dispatch({ type: INITIAL_STATE_WORKGROUP })
    store.dispatch({ type: INITIAL_STATE_PROCESSING })
    store.dispatch({ type: INITIAL_STATE_ANALYTICS })
    store.dispatch({ type: INITIAL_STATE_RIGHT })
    store.dispatch({ type: INITIAL_STATE_VERIFICATION })
    store.dispatch({ type: INITIAL_STATE_INVESTIGATION })
    store.dispatch({ type: INITIAL_STATE_ADJUSTMENT })
    store.dispatch({ type: INITIAL_STATE_APPROVAL })
    store.dispatch({ type: INITIAL_STATE_DISCHARGE_VOUCHER })
    store.dispatch({ type: INITIAL_STATE_RECOMMENDATION })
    store.dispatch({ type: INITIAL_STATE_FINANCE })
    store.dispatch({ type: INITIAL_STATE_CONSULTANT })
    store.dispatch({ type: INITIAL_STATE_MESSAGE })
    // Initial all the global state
    store.dispatch({ type: SIGNOUT_SUCCESS })
    // setAuth({})
    try {
      // eslint-disable-next-line no-unused-vars
      const response = await axios("/auth/logout", {
        withCredentials: true
      })
    } catch (err) {
      console.error(err)
    }
  }

  return logout
}

export default useLogout
