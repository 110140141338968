import {
  LOADING_FINANCE_PAYMENT,
  STOP_LOADING_FINANCE_PAYMENT,
  FINANCE_PAYMENT_ERROR,
  SET_FINANCE_PAYMENTS,
  SET_FINANCE_PAYMENT,
  DELETE_FINANCE_PAYMENT,
  CLEAR_ERRORS_FINANCE_PAYMENT,
  INITIAL_STATE_FINANCE_PAYMENT,
  INITIAL_ERROR_FINANCE_PAYMENT,
  FINANCE_PAYMENT_SUCCESS,
  INITIAL_SUCCESS_FINANCE_PAYMENT
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all payments belong to customer
const initialState = {
  payments: [],
  payment: {},
  loading: false,
  errors: null,
  success: null
}

const FinancePaymentReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FINANCE_PAYMENT:
      return { ...state, loading: true }
    case STOP_LOADING_FINANCE_PAYMENT:
      return { ...state, loading: false }
    case FINANCE_PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_FINANCE_PAYMENTS:
      return { ...state, payments: action.payload, loading: false }
    case SET_FINANCE_PAYMENT:
      return { ...state, payment: action.payload, loading: false }
    case DELETE_FINANCE_PAYMENT:
      return {
        ...state,
        payments: state.payments.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_FINANCE_PAYMENT:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_FINANCE_PAYMENT:
      return { ...state, loading: false, errors: null }
    case FINANCE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_FINANCE_PAYMENT:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_FINANCE_PAYMENT:
      return initialState
    default:
      return state
  }
}

export default FinancePaymentReducer
