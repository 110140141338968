import { useLocation, Navigate, Outlet } from "react-router-dom"
// import jwt_decode from "jwt-decode"
// Redux Stuff
import { connect } from "react-redux"

const RequireAuth = ({ user }) => {
  const location = useLocation()
  //   const decoded = user?.token ? jwt_decode(user.token) : undefined
  //   const roles = decoded?.roles || []

  return user?.isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/signin" state={{ from: location }} replace />
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(RequireAuth)
