import {
  LOADING_ANALYTICS,
  STOP_LOADING_ANALYTICS,
  ANALYTICS_ERROR,
  SET_ANALYTICS_CLAIMS,
  CLEAR_ERRORS_ANALYTICS,
  INITIAL_STATE_ANALYTICS,
  INITIAL_ERROR_ANALYTICS,
  ANALYTICS_SUCCESS,
  INITIAL_SUCCESS_ANALYTICS
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  claims: {},
  loading: false,
  errors: null,
  success: null
}

const AnalyticsReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ANALYTICS:
      return { ...state, loading: true }
    case STOP_LOADING_ANALYTICS:
      return { ...state, loading: false }
    case ANALYTICS_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_ANALYTICS_CLAIMS:
      return { ...state, claims: action.payload, loading: false }
    case INITIAL_ERROR_ANALYTICS:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_ANALYTICS:
      return { ...state, loading: false, errors: null }
    case ANALYTICS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_ANALYTICS:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_ANALYTICS:
      return initialState
    default:
      return state
  }
}

export default AnalyticsReducer
