import {
  LOADING_WORKGROUP,
  STOP_LOADING_WORKGROUP,
  WORKGROUP_ERROR,
  SET_WORKGROUPS,
  SET_WORKGROUP,
  SET_WORKGROUP_BRANCHES,
  SET_WORKGROUP_BRANCHE,
  DELETE_WORKGROUP,
  CLEAR_ERRORS_WORKGROUP,
  INITIAL_STATE_WORKGROUP,
  INITIAL_ERROR_WORKGROUP,
  WORKGROUP_SUCCESS,
  INITIAL_SUCCESS_WORKGROUP
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  workgroups: [],
  workgroup: {},
  workgroupBranches: [],
  workgroupBranche: {},
  loading: false,
  errors: null,
  success: null
}

const WorkgroupReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_WORKGROUP:
      return { ...state, loading: true }
    case STOP_LOADING_WORKGROUP:
      return { ...state, loading: false }
    case WORKGROUP_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_WORKGROUPS:
      return { ...state, workgroups: action.payload, loading: false }
    case SET_WORKGROUP:
      return { ...state, workgroup: action.payload, loading: false }
    case SET_WORKGROUP_BRANCHES:
      return { ...state, workgroupBranches: action.payload, loading: false }
    case SET_WORKGROUP_BRANCHE:
      return { ...state, workgroupBranche: action.payload, loading: false }
    case DELETE_WORKGROUP:
      return {
        ...state,
        workgroup: {
          ...state.workgroup,
          Branche_workgroups: state.workgroup.Branche_workgroups.filter(
            (item) => item.id !== action.payload
          )
        },
        loading: false
      }
    case INITIAL_ERROR_WORKGROUP:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_WORKGROUP:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_WORKGROUP:
      return initialState
    case WORKGROUP_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_WORKGROUP:
      return {
        ...state,
        success: null
      }
    default:
      return state
  }
}

export default WorkgroupReducer
