import {
  LOADING_FINANCE_CHECKTREASURY,
  STOP_LOADING_FINANCE_CHECKTREASURY,
  FINANCE_CHECKTREASURY_ERROR,
  SET_FINANCE_CHECKTREASURIES,
  SET_FINANCE_CHECKTREASURY,
  DELETE_FINANCE_CHECKTREASURY,
  CLEAR_ERRORS_FINANCE_CHECKTREASURY,
  INITIAL_STATE_FINANCE_CHECKTREASURY,
  INITIAL_ERROR_FINANCE_CHECKTREASURY,
  FINANCE_CHECKTREASURY_SUCCESS,
  INITIAL_SUCCESS_FINANCE_CHECKTREASURY
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all checktreasuries belong to customer
const initialState = {
  checktreasuries: [],
  checktreasury: {},
  loading: false,
  errors: null,
  success: null
}

const FinanceCheckTreasuryReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_FINANCE_CHECKTREASURY:
      return { ...state, loading: true }
    case STOP_LOADING_FINANCE_CHECKTREASURY:
      return { ...state, loading: false }
    case FINANCE_CHECKTREASURY_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_FINANCE_CHECKTREASURIES:
      return { ...state, checktreasuries: action.payload, loading: false }
    case SET_FINANCE_CHECKTREASURY:
      return { ...state, checktreasury: action.payload, loading: false }
    case DELETE_FINANCE_CHECKTREASURY:
      return {
        ...state,
        checktreasuries: state.checktreasuries.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_FINANCE_CHECKTREASURY:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_FINANCE_CHECKTREASURY:
      return { ...state, loading: false, errors: null }
    case FINANCE_CHECKTREASURY_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_FINANCE_CHECKTREASURY:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_FINANCE_CHECKTREASURY:
      return initialState
    default:
      return state
  }
}

export default FinanceCheckTreasuryReducer
