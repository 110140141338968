import {
  LOADING_MESSAGE,
  STOP_LOADING_MESSAGE,
  SET_MESSAGES,
  SET_MESSAGE,
  DELETE_MESSAGE,
  INITIAL_STATE_MESSAGE,
  MESSAGE_ERROR,
  CLEAR_ERRORS_MESSAGE,
  INITIAL_ERROR_MESSAGE,
  MESSAGE_SUCCESS,
  INITIAL_SUCCESS_MESSAGE
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  messages: [],
  message: {},
  loading: false,
  errors: null,
  success: null
}

const MessageReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_MESSAGE:
      return { ...state, loading: true }
    case STOP_LOADING_MESSAGE:
      return { ...state, loading: false }
    case MESSAGE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case MESSAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_MESSAGE:
      return {
        ...state,
        success: null
      }
    case SET_MESSAGES:
      return { ...state, messages: action.payload, loading: false }
    case SET_MESSAGE:
      return { ...state, message: action.payload, loading: false }
    case DELETE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_MESSAGE:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_MESSAGE:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_MESSAGE:
      return initialState
    default:
      return state
  }
}

export default MessageReducer
