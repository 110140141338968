import {
  LOADING_APPROVAL,
  STOP_LOADING_APPROVAL,
  APPROVAL_ERROR,
  SET_APPROVALS,
  SET_APPROVAL,
  DELETE_APPROVAL,
  CLEAR_ERRORS_APPROVAL,
  INITIAL_STATE_APPROVAL,
  INITIAL_ERROR_APPROVAL,
  APPROVAL_SUCCESS,
  INITIAL_SUCCESS_APPROVAL
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all approvals belong to customer
const initialState = {
  approvals: [],
  approval: {},
  loading: false,
  errors: null,
  success: null
}

const ApprovalReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_APPROVAL:
      return { ...state, loading: true }
    case STOP_LOADING_APPROVAL:
      return { ...state, loading: false }
    case APPROVAL_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_APPROVALS:
      return { ...state, approvals: action.payload, loading: false }
    case SET_APPROVAL:
      return { ...state, approval: action.payload, loading: false }
    case DELETE_APPROVAL:
      return {
        ...state,
        approvals: state.approvals.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_APPROVAL:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_APPROVAL:
      return { ...state, loading: false, errors: null }
    case APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_APPROVAL:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_APPROVAL:
      return initialState
    default:
      return state
  }
}

export default ApprovalReducer
