/* eslint-disable no-undef */
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { disableReactDevTools } from "@fvilers/disable-react-devtools"
import reportWebVitals from "./reportWebVitals"
// MUI Stuff
import { createTheme, ThemeProvider } from "@mui/material/styles"
import themeObject from "./utils/theme"
// Redux Stuff
import { persistor, store } from "./redux/Store"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

if (process.env.NODE_ENV === "production") {
  disableReactDevTools()
}

// Set theme Material UI
const theme = createTheme(themeObject)

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
