import axios from "axios"
// eslint-disable-next-line no-undef
const BASE_URL = process.env.REACT_APP_BASE_URL

export default axios.create({
  baseURL: BASE_URL
})

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true
})

export const axiosPrivateWithoutHeader = axios.create({
  baseURL: BASE_URL,
  withCredentials: true
})

export const axiosPrivateBlob = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  responseType: "blob"
})
