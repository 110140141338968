import {
  LOADING_INVESTIGATION,
  STOP_LOADING_INVESTIGATION,
  INVESTIGATION_ERROR,
  SET_INVESTIGATIONS,
  SET_INVESTIGATION,
  DELETE_INVESTIGATION,
  CLEAR_ERRORS_INVESTIGATION,
  INITIAL_STATE_INVESTIGATION,
  INITIAL_ERROR_INVESTIGATION,
  INVESTIGATION_SUCCESS,
  INITIAL_SUCCESS_INVESTIGATION
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all investigations belong to customer
const initialState = {
  investigations: [],
  investigation: {},
  loading: false,
  errors: null,
  success: null
}

const InvestigationReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_INVESTIGATION:
      return { ...state, loading: true }
    case STOP_LOADING_INVESTIGATION:
      return { ...state, loading: false }
    case INVESTIGATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_INVESTIGATIONS:
      return { ...state, investigations: action.payload, loading: false }
    case SET_INVESTIGATION:
      return { ...state, investigation: action.payload, loading: false }
    case DELETE_INVESTIGATION:
      return {
        ...state,
        investigations: state.investigations.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_INVESTIGATION:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_INVESTIGATION:
      return { ...state, loading: false, errors: null }
    case INVESTIGATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_INVESTIGATION:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_INVESTIGATION:
      return initialState
    default:
      return state
  }
}

export default InvestigationReducer
