import React from "react"
import { Outlet } from "react-router-dom"
// MUI Stuff
import { styled } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Container from "@mui/material/Container"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import Avatar from "@mui/material/Avatar"
import Stack from "@mui/material/Stack"
// Icons
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
// Images
import LogoHollard from "../../images/hollard-logo.png"
// Components
import { secondaryListItems } from "../dashboard/ListItems"
import MainListItems from "../dashboard/MainListItems"
import Copyright from "../Copyright"
import Logout from "../users/Logout"
import MenuTypeOfPolicy from "../users/MenuTypeOfPolicy"
import AccountMenu from "../users/AccountMenu"
// Redux Stuff
import { connect } from "react-redux"

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open"
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    "& .MuiDrawer-paper": {
      backgroundColor: "#8f7b9b",
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      boxSizing: "border-box",
      ...(!open && {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
          width: theme.spacing(9)
        }
      })
    }
  })
)

function DashboardContent(props) {
  const { userData } = props
  const [open, setOpen] = React.useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }
  // console.log("user data---", userData)

  // Get name of User Branch
  const markupShowBranchName = userData.Branche && (
    <Stack
      direction="column"
      spacing={1}
      sx={{ textAlign: "center", alignItems: "center", paddingTop: 2 }}
    >
      <Avatar alt="Bank" src={userData?.Branche?.picture} sx={{ width: 56, height: 56 }} />
      <Typography variant="subtitle2" gutterBottom>
        {userData?.Branche?.name.toUpperCase()}
      </Typography>
    </Stack>
  )

  // Get name of User Intermediary
  const markupShowIntermediaryName = userData.Intermediarie && (
    <Stack
      direction="column"
      spacing={1}
      sx={{ textAlign: "center", alignItems: "center", paddingTop: 2 }}
    >
      <Avatar
        alt="Intermediary"
        src={userData?.Intermediarie?.picture}
        sx={{ width: 56, height: 56 }}
      />
      <Typography variant="subtitle2" gutterBottom>
        {userData?.Intermediarie?.name.toUpperCase()}
      </Typography>
    </Stack>
  )

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: "24px" // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" })
            }}
          >
            <MenuIcon />
          </IconButton>

          <img src={LogoHollard} alt="Logo" style={{ width: "8%", marginRight: "3%" }} />
          <MenuTypeOfPolicy />
          <Box nowrap="true" sx={{ flexGrow: 1 }} />

          <Logout />
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1]
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        {markupShowBranchName}
        {markupShowIntermediaryName}

        <List>
          <MainListItems />
        </List>
        <Divider />
        <List>{secondaryListItems}</List>
      </Drawer>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto"
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Grid container spacing={3}>
            {/* Load children */}
            <Outlet />
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </Box>
  )
}

function DashboardLayout(props) {
  const { userData } = props
  return <DashboardContent userData={userData} />
}

const mapStateToProps = (state) => ({
  userData: state.user.credentials
})

export default connect(mapStateToProps)(DashboardLayout)
