import React from "react"
import { useNavigate } from "react-router-dom"
// MUI Stuff
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogTitle from "@mui/material/DialogTitle"
// Redux Stuff
// import { connect } from "react-redux"
// import { signout } from "../../redux/actions/Auth"
// Component
import useLogout from "../../hooks/useLogout"

function Logout() {
  const navigate = useNavigate()
  const logout = useLogout()
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSignout = async () => {
    // setOpen(false)
    await logout()
    setOpen(false)
    navigate("/signin")
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        style={{
          fontSize: 12,
          color: "#fff",
          textTransform: "none"
          // marginRight: 0
        }}
      >
        Logout
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Do you want to logout ?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleSignout} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     signout: () => dispatch(signout())
//   }
// }

export default Logout
