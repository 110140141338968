import {
  LOADING_PROCESSING,
  STOP_LOADING_PROCESSING,
  PROCESSING_ERROR,
  SET_PROCESSINGS,
  CLEAR_ERRORS_PROCESSING,
  INITIAL_STATE_PROCESSING,
  INITIAL_ERROR_PROCESSING
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  processings: [],
  loading: false,
  errors: null
}

const ProcessingReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_PROCESSING:
      return { ...state, loading: true }
    case STOP_LOADING_PROCESSING:
      return { ...state, loading: false }
    case PROCESSING_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_PROCESSINGS:
      return { ...state, processings: action.payload, loading: false }
    case INITIAL_ERROR_PROCESSING:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_PROCESSING:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_PROCESSING:
      return initialState
    default:
      return state
  }
}

export default ProcessingReducer
