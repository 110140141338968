//*** USERS REDUCERS TYPES ***//
export const SET_USER = "SET_USER"
export const SET_USERS = "SET_USERS"
export const SET_USER_DETAILS = "SET_USER_DETAILS"
export const SET_USER_DETAILS_CREDENTIALS = "SET_USER_DETAILS_CREDENTIALS"
export const LOADING_USER = "LOADING_USER"
export const STOP_LOADING_USER = "STOP_LOADING_USER"
export const LOGIN_REQUEST = "LOGIN_REQUEST"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const STOP_LOGIN_REQUEST = "STOP_LOGIN_REQUEST"
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS"
export const SIGNOUT_ERROR = "SIGNOUT_ERROR"
export const SET_ERRORS_USER = "SET_ERRORS_USER"
export const USER_ERROR = "USER_ERROR"
export const CLEAR_ERRORS_USER = "CLEAR_ERRORS_USER"
export const INITIAL_ERROR_USER = "INITIAL_ERROR_USER"
export const USER_SUCCESS = "USER_SUCCESS"
export const CLEAR_SUCCESS_USER = "CLEAR_SUCCESS_USER"
export const INITIAL_STATE_USER = "INITIAL_STATE_USER"
export const INITIAL_SUCCESS_USER = "INITIAL_SUCCESS_USER"
// Others with USERS
export const CREATE_USER_ACCOUNT = "CREATE_USER_ACCOUNT"
export const SET_TYPE_POLICY_USER = "SET_TYPE_POLICY_USER"
// Branche reducers types
export const LOADING_BRANCHE = "LOADING_BRANCHE"
export const STOP_LOADING_BRANCHE = "STOP_LOADING_BRANCHE"
export const CLEAR_ERRORS_BRANCHE = "CLEAR_ERRORS_BRANCHE"
export const INITIAL_STATE_BRANCHE = "INITIAL_STATE_BRANCHE"
export const INITIAL_ERROR_BRANCHE = "INITIAL_ERROR_BRANCHE"
export const BRANCHE_ERROR = "BRANCHE_ERROR"
export const SET_BRANCHES = "SET_BRANCHES"
export const SET_BRANCHE = "SET_BRANCHE"
export const DELETE_BRANCHE = "DELETE_BRANCHE"
// Confirm Contact reducers types
export const LOADING_CONFIRMCONTACT = "LOADING_CONFIRMCONTACT"
export const STOP_LOADING_CONFIRMCONTACT = "STOP_LOADING_CONFIRMCONTACT"
export const CONFIRMCONTACT_ERROR = "CONFIRMCONTACT_ERROR"
export const VALIDATE_CONFIRMCONTACT = "VALIDATE_CONFIRMCONTACT"
export const SET_CONFIRMCONTACT = "SET_CONFIRMCONTACT"
export const CLEAR_ERRORS_CONFIRMCONTACT = "CLEAR_ERRORS_CONFIRMCONTACT"
export const INITIAL_STATE_CONFIRMCONTACT = "INITIAL_STATE_CONFIRMCONTACT"
export const INITIAL_ERROR_CONFIRMCONTACT = "INITIAL_ERROR_CONFIRMCONTACT"
export const CONFIRMCONTACT_SUCCESS = "CONFIRMCONTACT_SUCCESS"
export const INITIAL_SUCCESS_CONFIRMCONTACT = "INITIAL_SUCCESS_CONFIRMCONTACT"
// Claims reducers types
export const LOADING_CLAIM = "LOADING_CLAIM"
export const STOP_LOADING_CLAIM = "STOP_LOADING_CLAIM"
export const CLEAR_ERRORS_CLAIM = "CLEAR_ERRORS_CLAIM"
export const INITIAL_STATE_CLAIM = "INITIAL_STATE_CLAIM"
export const INITIAL_ERROR_CLAIM = "INITIAL_ERROR_CLAIM"
export const CLAIM_ERROR = "CLAIM_ERROR"
export const SET_CLAIMS = "SET_CLAIMS"
export const SET_RECENT_CLAIMS = "SET_RECENT_CLAIMS"
export const SET_SEARCH_CLAIMS = "SET_SEARCH_CLAIMS"
export const SET_NOTASSIGN_CLAIMS = "SET_NOTASSIGN_CLAIMS"
export const SET_ASSIGN_CLAIMS = "SET_ASSIGN_CLAIMS"
export const SET_CLAIMS_CUTOMER = "SET_CLAIMS_CUTOMER"
export const SET_CLAIM = "SET_CLAIM"
export const SET_CLAIM_LOGS = "SET_CLAIM_LOGS"
export const DELETE_CLAIM = "DELETE_CLAIM"
export const CLAIM_SUCCESS = "CLAIM_SUCCESS"
export const INITIAL_SUCCESS_CLAIM = "INITIAL_SUCCESS_CLAIM"
// Corporate reducers types
export const LOADING_CORPORATE = "LOADING_CORPORATE"
export const STOP_LOADING_CORPORATE = "STOP_LOADING_CORPORATE"
export const CLEAR_ERRORS_CORPORATE = "CLEAR_ERRORS_CORPORATE"
export const INITIAL_STATE_CORPORATE = "INITIAL_STATE_CORPORATE"
export const INITIAL_ERROR_CORPORATE = "INITIAL_ERROR_CORPORATE"
export const CORPORATE_ERROR = "CORPORATE_ERROR"
export const SET_CORPORATES = "SET_CORPORATES"
export const SET_CORPORATE_USERS = "SET_CORPORATE_USERS"
export const SET_CORPORATE_USER = "SET_CORPORATE_USER"
export const SET_CORPORATE = "SET_CORPORATE"
export const DELETE_CORPORATE = "DELETE_CORPORATE"
// Intermediarie reducers types
export const LOADING_INTERMEDIARIE = "LOADING_INTERMEDIARIE"
export const STOP_LOADING_INTERMEDIARIE = "STOP_LOADING_INTERMEDIARIE"
export const CLEAR_ERRORS_INTERMEDIARIE = "CLEAR_ERRORS_INTERMEDIARIE"
export const INITIAL_STATE_INTERMEDIARIE = "INITIAL_STATE_INTERMEDIARIE"
export const INITIAL_ERROR_INTERMEDIARIE = "INITIAL_ERROR_INTERMEDIARIE"
export const INTERMEDIARIE_ERROR = "INTERMEDIARIE_ERROR"
export const INTERMEDIARIE_SUCCESS = "INTERMEDIARIE_SUCCESS"
export const INITIAL_SUCCESS_INTERMEDIARIE = "INITIAL_SUCCESS_INTERMEDIARIE"
export const CLEAR_SUCCESS_INTERMEDIARIE = "CLEAR_SUCCESS_INTERMEDIARIE"
export const SET_INTERMEDIARIES = "SET_INTERMEDIARIES"
export const SET_INTERMEDIARIE_USERS = "SET_INTERMEDIARIE_USERS"
export const SET_INTERMEDIARIE_USER = "SET_INTERMEDIARIE_USER"
export const SET_INTERMEDIARIE = "SET_INTERMEDIARIE"
export const DELETE_INTERMEDIARIE = "DELETE_INTERMEDIARIE"
// Workgroup reducers types
export const LOADING_WORKGROUP = "LOADING_WORKGROUP"
export const STOP_LOADING_WORKGROUP = "STOP_LOADING_WORKGROUP"
export const CLEAR_ERRORS_WORKGROUP = "CLEAR_ERRORS_WORKGROUP"
export const INITIAL_STATE_WORKGROUP = "INITIAL_STATE_WORKGROUP"
export const INITIAL_ERROR_WORKGROUP = "INITIAL_ERROR_WORKGROUP"
export const WORKGROUP_SUCCESS = "WORKGROUP_SUCCESS"
export const INITIAL_SUCCESS_WORKGROUP = "INITIAL_SUCCESS_WORKGROUP"
export const WORKGROUP_ERROR = "WORKGROUP_ERROR"
export const SET_WORKGROUPS = "SET_WORKGROUPS"
export const SET_WORKGROUP = "SET_WORKGROUP"
export const SET_WORKGROUP_BRANCHES = "SET_WORKGROUP_BRANCHES"
export const SET_WORKGROUP_BRANCHE = "SET_WORKGROUP_BRANCHE"
export const DELETE_WORKGROUP = "DELETE_WORKGROUP"
// Processings reducers types
export const LOADING_PROCESSING = "LOADING_PROCESSING"
export const STOP_LOADING_PROCESSING = "STOP_LOADING_PROCESSING"
export const CLEAR_ERRORS_PROCESSING = "CLEAR_ERRORS_PROCESSING"
export const INITIAL_STATE_PROCESSING = "INITIAL_STATE_PROCESSING"
export const INITIAL_ERROR_PROCESSING = "INITIAL_ERROR_PROCESSING"
export const PROCESSING_ERROR = "PROCESSING_ERROR"
export const SET_PROCESSINGS = "SET_PROCESSINGS"
// Confirm Contact reducers types
export const LOADING_ANALYTICS = "LOADING_ANALYTICS"
export const STOP_LOADING_ANALYTICS = "STOP_LOADING_ANALYTICS"
export const ANALYTICS_ERROR = "ANALYTICS_ERROR"
export const SET_ANALYTICS_CLAIMS = "SET_ANALYTICS_CLAIMS"
export const CLEAR_ERRORS_ANALYTICS = "CLEAR_ERRORS_ANALYTICS"
export const INITIAL_STATE_ANALYTICS = "INITIAL_STATE_ANALYTICS"
export const INITIAL_ERROR_ANALYTICS = "INITIAL_ERROR_ANALYTICS"
export const ANALYTICS_SUCCESS = "ANALYTICS_SUCCESS"
export const INITIAL_SUCCESS_ANALYTICS = "INITIAL_SUCCESS_ANALYTICS"
// Right reducers types
export const LOADING_RIGHT = "LOADING_RIGHT"
export const STOP_LOADING_RIGHT = "STOP_LOADING_RIGHT"
export const CLEAR_ERRORS_RIGHT = "CLEAR_ERRORS_RIGHT"
export const INITIAL_STATE_RIGHT = "INITIAL_STATE_RIGHT"
export const INITIAL_ERROR_RIGHT = "INITIAL_ERROR_RIGHT"
export const RIGHT_ERROR = "RIGHT_ERROR"
export const SET_RIGHT = "SET_RIGHT"
export const SET_RIGHTS = "SET_RIGHTS"
export const DELETE_RIGHT = "DELETE_RIGHT"
export const UPDATE_RIGHT = "UPDATE_RIGHT"
// Verifications reducers types
export const LOADING_VERIFICATION = "LOADING_VERIFICATION"
export const STOP_LOADING_VERIFICATION = "STOP_LOADING_VERIFICATION"
export const CLEAR_ERRORS_VERIFICATION = "CLEAR_ERRORS_VERIFICATION"
export const VERIFICATION_ERROR = "VERIFICATION_ERROR"
export const INITIAL_ERROR_VERIFICATION = "INITIAL_ERROR_VERIFICATION"
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS"
export const INITIAL_SUCCESS_VERIFICATION = "INITIAL_SUCCESS_VERIFICATION"
export const SET_VERIFICATIONS = "SET_VERIFICATIONS"
export const SET_VERIFICATION = "SET_VERIFICATION"
export const DELETE_VERIFICATION = "DELETE_VERIFICATION"
export const INITIAL_STATE_VERIFICATION = "INITIAL_STATE_VERIFICATION"
// Investigation reducers types
export const LOADING_INVESTIGATION = "LOADING_INVESTIGATION"
export const STOP_LOADING_INVESTIGATION = "STOP_LOADING_INVESTIGATION"
export const CLEAR_ERRORS_INVESTIGATION = "CLEAR_ERRORS_INVESTIGATION"
export const INVESTIGATION_ERROR = "INVESTIGATION_ERROR"
export const INITIAL_ERROR_INVESTIGATION = "INITIAL_ERROR_INVESTIGATION"
export const INVESTIGATION_SUCCESS = "INVESTIGATION_SUCCESS"
export const INITIAL_SUCCESS_INVESTIGATION = "INITIAL_SUCCESS_INVESTIGATION"
export const SET_INVESTIGATIONS = "SET_INVESTIGATIONS"
export const SET_INVESTIGATION = "SET_INVESTIGATION"
export const DELETE_INVESTIGATION = "DELETE_INVESTIGATION"
export const INITIAL_STATE_INVESTIGATION = "INITIAL_STATE_INVESTIGATION"
// Adjustment reducers types
export const LOADING_ADJUSTMENT = "LOADING_ADJUSTMENT"
export const STOP_LOADING_ADJUSTMENT = "STOP_LOADING_ADJUSTMENT"
export const CLEAR_ERRORS_ADJUSTMENT = "CLEAR_ERRORS_ADJUSTMENT"
export const ADJUSTMENT_ERROR = "ADJUSTMENT_ERROR"
export const INITIAL_ERROR_ADJUSTMENT = "INITIAL_ERROR_ADJUSTMENT"
export const ADJUSTMENT_SUCCESS = "ADJUSTMENT_SUCCESS"
export const INITIAL_SUCCESS_ADJUSTMENT = "INITIAL_SUCCESS_ADJUSTMENT"
export const SET_ADJUSTMENTS = "SET_ADJUSTMENTS"
export const SET_ADJUSTMENT = "SET_ADJUSTMENT"
export const DELETE_ADJUSTMENT = "DELETE_ADJUSTMENT"
export const INITIAL_STATE_ADJUSTMENT = "INITIAL_STATE_ADJUSTMENT"
// Approval reducers types
export const LOADING_APPROVAL = "LOADING_APPROVAL"
export const STOP_LOADING_APPROVAL = "STOP_LOADING_APPROVAL"
export const CLEAR_ERRORS_APPROVAL = "CLEAR_ERRORS_APPROVAL"
export const APPROVAL_ERROR = "APPROVAL_ERROR"
export const INITIAL_ERROR_APPROVAL = "INITIAL_ERROR_APPROVAL"
export const APPROVAL_SUCCESS = "APPROVAL_SUCCESS"
export const INITIAL_SUCCESS_APPROVAL = "INITIAL_SUCCESS_APPROVAL"
export const SET_APPROVALS = "SET_APPROVALS"
export const SET_APPROVAL = "SET_APPROVAL"
export const DELETE_APPROVAL = "DELETE_APPROVAL"
export const INITIAL_STATE_APPROVAL = "INITIAL_STATE_APPROVAL"
// Discharge voucher reducers types
export const LOADING_DISCHARGE_VOUCHER = "LOADING_DISCHARGE_VOUCHER"
export const STOP_LOADING_DISCHARGE_VOUCHER = "STOP_LOADING_DISCHARGE_VOUCHER"
export const CLEAR_ERRORS_DISCHARGE_VOUCHER = "CLEAR_ERRORS_DISCHARGE_VOUCHER"
export const DISCHARGE_VOUCHER_ERROR = "DISCHARGE_VOUCHER_ERROR"
export const INITIAL_ERROR_DISCHARGE_VOUCHER = "INITIAL_ERROR_DISCHARGE_VOUCHER"
export const DISCHARGE_VOUCHER_SUCCESS = "DISCHARGE_VOUCHER_SUCCESS"
export const INITIAL_SUCCESS_DISCHARGE_VOUCHER = "INITIAL_SUCCESS_DISCHARGE_VOUCHER"
export const SET_DISCHARGE_VOUCHERS = "SET_DISCHARGE_VOUCHERS"
export const SET_DISCHARGE_VOUCHER = "SET_DISCHARGE_VOUCHER"
export const DELETE_DISCHARGE_VOUCHER = "DELETE_DISCHARGE_VOUCHER"
export const INITIAL_STATE_DISCHARGE_VOUCHER = "INITIAL_STATE_DISCHARGE_VOUCHER"
// Recommendation reducers types
export const LOADING_RECOMMENDATION = "LOADING_RECOMMENDATION"
export const STOP_LOADING_RECOMMENDATION = "STOP_LOADING_RECOMMENDATION"
export const CLEAR_ERRORS_RECOMMENDATION = "CLEAR_ERRORS_RECOMMENDATION"
export const RECOMMENDATION_ERROR = "RECOMMENDATION_ERROR"
export const INITIAL_ERROR_RECOMMENDATION = "INITIAL_ERROR_RECOMMENDATION"
export const RECOMMENDATION_SUCCESS = "RECOMMENDATION_SUCCESS"
export const INITIAL_SUCCESS_RECOMMENDATION = "INITIAL_SUCCESS_RECOMMENDATION"
export const SET_RECOMMENDATIONS = "SET_RECOMMENDATIONS"
export const SET_RECOMMENDATION = "SET_RECOMMENDATION"
export const DELETE_RECOMMENDATION = "DELETE_RECOMMENDATION"
export const INITIAL_STATE_RECOMMENDATION = "INITIAL_STATE_RECOMMENDATION"
// Finance reducers types
export const LOADING_FINANCE = "LOADING_FINANCE"
export const STOP_LOADING_FINANCE = "STOP_LOADING_FINANCE"
export const CLEAR_ERRORS_FINANCE = "CLEAR_ERRORS_FINANCE"
export const FINANCE_ERROR = "FINANCE_ERROR"
export const INITIAL_ERROR_FINANCE = "INITIAL_ERROR_FINANCE"
export const FINANCE_SUCCESS = "FINANCE_SUCCESS"
export const INITIAL_SUCCESS_FINANCE = "INITIAL_SUCCESS_FINANCE"
export const SET_FINANCES = "SET_FINANCES"
export const SET_FINANCE = "SET_FINANCE"
export const DELETE_FINANCE = "DELETE_FINANCE"
export const INITIAL_STATE_FINANCE = "INITIAL_STATE_RECOMMENDATION"
// Consultants reducers types
export const LOADING_CONSULTANT = "LOADING_CONSULTANT"
export const STOP_LOADING_CONSULTANT = "STOP_LOADING_CONSULTANT"
export const CLEAR_ERRORS_CONSULTANT = "CLEAR_ERRORS_CONSULTANT"
export const INITIAL_STATE_CONSULTANT = "INITIAL_STATE_CONSULTANT"
export const INITIAL_ERROR_CONSULTANT = "INITIAL_ERROR_CONSULTANT"
export const CONSULTANT_ERROR = "CONSULTANT_ERROR"
export const SET_CONSULTANTS = "SET_CONSULTANTS"
export const SET_CONSULTANT = "SET_CONSULTANT"
export const DELETE_CONSULTANT = "DELETE_CONSULTANT"
// Message reducers types
export const LOADING_MESSAGE = "LOADING_MESSAGE"
export const STOP_LOADING_MESSAGE = "STOP_LOADING_MESSAGE"
export const CLEAR_ERRORS_MESSAGE = "CLEAR_ERRORS_MESSAGE"
export const INITIAL_STATE_MESSAGE = "INITIAL_STATE_MESSAGE"
export const INITIAL_ERROR_MESSAGE = "INITIAL_ERROR_MESSAGE"
export const MESSAGE_ERROR = "MESSAGE_ERROR"
export const INITIAL_SUCCESS_MESSAGE = "INITIAL_SUCCESS_MESSAGE"
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS"
export const SET_MESSAGES = "SET_MESSAGES"
export const SET_MESSAGE = "SET_MESSAGE"
export const DELETE_MESSAGE = "DELETE_MESSAGE"
// External reducers types
export const LOADING_EXTERNAL = "LOADING_EXTERNAL"
export const LOADING_EXTERNAL_REQUEST = "LOADING_EXTERNAL_REQUEST"
export const STOP_LOADING_EXTERNAL = "STOP_LOADING_EXTERNAL"
export const STOP_LOADING_EXTERNAL_REQUEST = "STOP_LOADING_EXTERNAL_REQUEST"
export const EXTERNAL_ERROR = "EXTERNAL_ERROR"
export const EXTERNAL_ERROR_REQUEST = "EXTERNAL_ERROR_REQUEST"
export const SET_INVESTIGATION_EXTERNAL = "SET_INVESTIGATION_EXTERNAL"
export const SET_CONSULTANT_EXTERNAL = "SET_CONSULTANT_EXTERNAL"
export const SET_CLAIM_EXTERNAL = "SET_CLAIM_EXTERNAL"
export const CLEAR_ERRORS_EXTERNAL = "CLEAR_ERRORS_EXTERNAL"
export const INITIAL_STATE_EXTERNAL = "INITIAL_STATE_EXTERNAL"
export const INITIAL_ERROR_EXTERNAL = "INITIAL_ERROR_EXTERNAL"
export const EXTERNAL_SUCCESS = "EXTERNAL_SUCCESS"
export const EXTERNAL_SUCCESS_REQUEST = "EXTERNAL_SUCCESS_REQUEST"
export const INITIAL_SUCCESS_EXTERNAL = "INITIAL_SUCCESS_EXTERNAL"
// Garages reducers types
export const LOADING_GARAGE = "LOADING_GARAGE"
export const STOP_LOADING_GARAGE = "STOP_LOADING_GARAGE"
export const CLEAR_ERRORS_GARAGE = "CLEAR_ERRORS_GARAGE"
export const INITIAL_STATE_GARAGE = "INITIAL_STATE_GARAGE"
export const INITIAL_ERROR_GARAGE = "INITIAL_ERROR_GARAGE"
export const GARAGE_ERROR = "GARAGE_ERROR"
export const SET_GARAGES = "SET_GARAGES"
export const SET_GARAGE = "SET_GARAGE"
export const DELETE_GARAGE = "DELETE_GARAGE"
// Garages reducers types
export const LOADING_POLICY = "LOADING_POLICY"
export const STOP_LOADING_POLICY = "STOP_LOADING_POLICY"
export const CLEAR_ERRORS_POLICY = "CLEAR_ERRORS_POLICY"
export const INITIAL_STATE_POLICY = "INITIAL_STATE_POLICY"
export const INITIAL_ERROR_POLICY = "INITIAL_ERROR_POLICY"
export const POLICY_ERROR = "POLICY_ERROR"
export const SET_POLICIES = "SET_POLICIES"
export const SET_CUSTOMER_POLICIES = "SET_CUSTOMER_POLICIES"
export const SET_POLICY = "SET_POLICY"
export const DELETE_POLICY = "DELETE_POLICY"
// Finance Meet Criteria reducers types
export const LOADING_FINANCE_MEETCRITERIA = "LOADING_FINANCE_MEETCRITERIA"
export const STOP_LOADING_FINANCE_MEETCRITERIA = "STOP_LOADING_FINANCE_MEETCRITERIA"
export const CLEAR_ERRORS_FINANCE_MEETCRITERIA = "CLEAR_ERRORS_FINANCE_MEETCRITERIA"
export const FINANCE_MEETCRITERIA_ERROR = "FINANCE_MEETCRITERIA_ERROR"
export const INITIAL_ERROR_FINANCE_MEETCRITERIA = "INITIAL_ERROR_FINANCE_MEETCRITERIA"
export const FINANCE_MEETCRITERIA_SUCCESS = "FINANCE_MEETCRITERIA_SUCCESS"
export const INITIAL_SUCCESS_FINANCE_MEETCRITERIA = "INITIAL_SUCCESS_FINANCE_MEETCRITERIA"
export const SET_FINANCE_MEETCRITERIAS = "SET_FINANCE_MEETCRITERIAS"
export const SET_FINANCE_MEETCRITERIA = "SET_FINANCE_MEETCRITERIA"
export const DELETE_FINANCE_MEETCRITERIA = "DELETE_FINANCE_MEETCRITERIA"
export const INITIAL_STATE_FINANCE_MEETCRITERIA = "INITIAL_STATE_FINANCE_MEETCRITERIA"
// Finance Check Treasury reducers types
export const LOADING_FINANCE_CHECKTREASURY = "LOADING_FINANCE_CHECKTREASURY"
export const STOP_LOADING_FINANCE_CHECKTREASURY = "STOP_LOADING_FINANCE_CHECKTREASURY"
export const CLEAR_ERRORS_FINANCE_CHECKTREASURY = "CLEAR_ERRORS_FINANCE_CHECKTREASURY"
export const FINANCE_CHECKTREASURY_ERROR = "FINANCE_CHECKTREASURY_ERROR"
export const INITIAL_ERROR_FINANCE_CHECKTREASURY = "INITIAL_ERROR_FINANCE_CHECKTREASURY"
export const FINANCE_CHECKTREASURY_SUCCESS = "FINANCE_CHECKTREASURY_SUCCESS"
export const INITIAL_SUCCESS_FINANCE_CHECKTREASURY = "INITIAL_SUCCESS_FINANCE_CHECKTREASURY"
export const SET_FINANCE_CHECKTREASURIES = "SET_FINANCE_CHECKTREASURIES"
export const SET_FINANCE_CHECKTREASURY = "SET_FINANCE_CHECKTREASURY"
export const DELETE_FINANCE_CHECKTREASURY = "DELETE_FINANCE_CHECKTREASURY"
export const INITIAL_STATE_FINANCE_CHECKTREASURY = "INITIAL_STATE_FINANCE_CHECKTREASURY"
// Finance Meet Criteria reducers types
export const LOADING_FINANCE_PAYMENT = "LOADING_FINANCE_PAYMENT"
export const STOP_LOADING_FINANCE_PAYMENT = "STOP_LOADING_FINANCE_PAYMENT"
export const CLEAR_ERRORS_FINANCE_PAYMENT = "CLEAR_ERRORS_FINANCE_PAYMENT"
export const FINANCE_PAYMENT_ERROR = "FINANCE_PAYMENT_ERROR"
export const INITIAL_ERROR_FINANCE_PAYMENT = "INITIAL_ERROR_FINANCE_PAYMENT"
export const FINANCE_PAYMENT_SUCCESS = "FINANCE_PAYMENT_SUCCESS"
export const INITIAL_SUCCESS_FINANCE_PAYMENT = "INITIAL_SUCCESS_FINANCE_PAYMENT"
export const SET_FINANCE_PAYMENTS = "SET_FINANCE_PAYMENTS"
export const SET_FINANCE_PAYMENT = "SET_FINANCE_PAYMENT"
export const DELETE_FINANCE_PAYMENT = "DELETE_FINANCE_PAYMENT"
export const INITIAL_STATE_FINANCE_PAYMENT = "INITIAL_STATE_FINANCE_PAYMENT"
