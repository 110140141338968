import React from "react"
// MUI Stuff
import withStyles from "@mui/styles/withStyles"
import Container from "@mui/material/Container"
import Box from "@mui/material/Box"
// import CircularProgress from "@mui/material/CircularProgress"
// Images
import LogoHollard from "../images/hollard-logo2.png"
import { ReactComponent as InfinityLoading } from "../images/svg/Infinity-1s-200px.svg"

const styles = (theme) => ({
  logoImage: {
    width: "50%",
    // marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      // left: "36%"
    }
  },
  textLoading: {
    width: "100%",
    maxWidth: 500,
    [theme.breakpoints.down("sm")]: {
      // left: "36%"
    }
  }
})

function PreloaderApp(props) {
  const { classes } = props

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: "50%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <img src={LogoHollard} alt="Logo" className={classes.logoImage} />
        <InfinityLoading width="20%" height="20%" />
      </Box>
    </Container>
  )
}

export default withStyles(styles)(PreloaderApp)
