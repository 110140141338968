import React from "react"
import { Routes, Route } from "react-router-dom"
// Components
import PreloaderApp from "./utils/PreloaderApp"
import PersistLogin from "./components/layout/auth/PersistLogin"
import RequireAuth from "./components/layout/auth/RequireAuth"
// import PreloaderWithoutTheme from "./utils/PreloaderWithoutTheme"
// Layout templates
import DashboardLayout from "./components/layout/DashboardLayout"
// Pages
const SignIn = React.lazy(() => import("./pages/auth/SignIn"))
const SignUp = React.lazy(() => import("./pages/auth/SignUp"))
const AccountValidation = React.lazy(() => import("./pages/auth/AccountValidation"))
const GenerateActivationLink = React.lazy(() => import("./pages/auth/GenerateActivationLink"))
const RequestForUpdatePassword = React.lazy(() => import("./pages/auth/RequestForUpdatePassword"))
const UpdatePasswordAccount = React.lazy(() => import("./pages/auth/UpdatePasswordAccount"))
const TestPage = React.lazy(() => import("./pages/TestPage"))

// General Dashboard
const Home = React.lazy(() => import("./pages/Home"))
// const HomeDashboard = React.lazy(() => import("./pages/HomeDashboard"))
const Support = React.lazy(() => import("./pages/Support"))
// Users
const AllUsers = React.lazy(() => import("./pages/AllUsers"))
const CreateUser = React.lazy(() => import("./pages/users/CreateUser"))
const AttachBrancheToUser = React.lazy(() => import("./pages/users/AttachBrancheToUser"))
const UserViewDetails = React.lazy(() => import("./pages/users/ViewDetails"))
const UpdateUserRight = React.lazy(() => import("./pages/users/UpdateUserRight"))
const UpdateUserPassword = React.lazy(() => import("./pages/users/UpdateUserPassword"))
const UpdateRoleUser = React.lazy(() => import("./pages/users/UpdateRoleUser"))
const UpdateDetailsUser = React.lazy(() => import("./pages/users/UpdateDetailsUser"))
const GeneralProfileSettings = React.lazy(
  () => import("./pages/users/profile/GeneralProfileSettings")
)
const UpdateMyUserPassword = React.lazy(() => import("./pages/users/profile/UpdateMyUserPassword"))
const UpdateProfileDetails = React.lazy(() => import("./pages/users/profile/UpdateProfileDetails"))
// Branches
const AllBranches = React.lazy(() => import("./pages/AllBranches"))
const CreateBranche = React.lazy(() => import("./pages/branches/CreateBranche"))
// Banks
const AllBanks = React.lazy(() => import("./pages/AllBanks"))
const CreateBank = React.lazy(() => import("./pages/banks/CreateBank"))
// Intermediaries
const AllIntermediaries = React.lazy(() => import("./pages/AllIntermediaries"))
const CreateIntermediarie = React.lazy(() => import("./pages/intermediaries/CreateIntermediarie"))
const ViewIntermediarie = React.lazy(() => import("./pages/intermediaries/ViewIntermediarie"))
const CreateUserIntermediarie = React.lazy(
  () => import("./pages/intermediaries/CreateUserIntermediarie")
)
const IntermediaryViewUserDetails = React.lazy(
  () => import("./pages/intermediaries/ViewUserDetails")
)
// Claims
const AllClaims = React.lazy(() => import("./pages/AllClaims"))
const AllPendingClaims = React.lazy(() => import("./pages/claims/AllPendingClaims"))
const AllOnHoldClaims = React.lazy(() => import("./pages/claims/AllOnHoldClaims"))
const AllRejectedClaims = React.lazy(() => import("./pages/claims/AllRejectedClaims"))
const AllDeclinedClaims = React.lazy(() => import("./pages/claims/AllDeclinedClaims"))
const AllCompletedClaims = React.lazy(() => import("./pages/claims/AllCompletedClaims"))
const AllNotAssignClaims = React.lazy(() => import("./pages/claims/AllNotAssignClaims"))
const AllAssignClaims = React.lazy(() => import("./pages/claims/AllAssignClaims"))
const ViewClaimStatus = React.lazy(() => import("./pages/claims/ViewClaimStatus"))
const SubmitUploadScanClaims = React.lazy(() => import("./pages/claims/SubmitUploadScanClaims"))
const SubmitOnPortailClaims = React.lazy(() => import("./pages/claims/SubmitOnPortailClaims"))
const SubmitClaimsStaff = React.lazy(() => import("./pages/claims/SubmitClaimsStaff"))
const ViewClaimDetails = React.lazy(() => import("./pages/claims/ViewClaimDetails"))
const ViewCustomerDetails = React.lazy(() => import("./pages/claims/ViewCustomerDetails"))
const ViewCustomerPolicyDetails = React.lazy(
  () => import("./pages/claims/ViewCustomerPolicyDetails")
)
const FirstAssignClaimToUser = React.lazy(() => import("./pages/claims/FirstAssignClaimToUser"))
const ViewClaimLogs = React.lazy(() => import("./pages/claims/ViewClaimLogs"))
const ViewChatClaimDetails = React.lazy(() => import("./pages/claims/ViewChatClaimDetails"))
const ViewClaimDetailsFromFinance = React.lazy(
  () => import("./pages/claims/ViewClaimDetailsFromFinance")
)
const ArchiveClaims = React.lazy(() => import("./pages/claims/ArchiveClaims"))
const AllPaymentsRequest = React.lazy(() => import("./pages/claims/AllPaymentsRequest"))
const AllClaimsWaitingForApproval = React.lazy(
  () => import("./pages/claims/AllClaimsWaitingForApproval")
)
const RecommandationStageUpdate = React.lazy(
  () => import("./pages/claims/update/RecommandationStageUpdate")
)
const AllClaimsSupplementary = React.lazy(
  () => import("./pages/claims/supplementary/AllClaimsSupplementary")
)
const UpdateUploadScanClaims = React.lazy(
  () => import("./pages/claims/update/UpdateUploadScanClaims")
)
const UpdateOnPortalClaims = React.lazy(() => import("./pages/claims/update/UpdateOnPortalClaims"))
const UpdateFilesClaims = React.lazy(() => import("./pages/claims/update/UpdateFilesClaims"))
const InHouseDischargeVoucherDetails = React.lazy(
  () => import("./pages/claims/dischargeVoucher/DischargeVoucherDetails")
)
const InHouseDischargeVoucherOfflineSignature = React.lazy(
  () => import("./pages/claims/dischargeVoucher/OfflineSignature")
)
const InHouseDischargeVoucherOnlineSignature = React.lazy(
  () => import("./pages/claims/dischargeVoucher/OnlineSignature")
)
// Workgroups & Branches
const AllMainWorkgroups = React.lazy(() => import("./pages/AllMainWorkgroups"))
const AllWorkgroupBranches = React.lazy(() => import("./pages/workgroups/AllWorkgroupBranches"))
const CreateWorkgroupBranche = React.lazy(() => import("./pages/workgroups/CreateWorkgroupBranche"))
const AllUsersOfWorkgroupBranche = React.lazy(
  () => import("./pages/workgroups/AllUsersOfWorkgroupBranche")
)
const AttachWorkgroupToUser = React.lazy(() => import("./pages/workgroups/AttachWorkgroupToUser"))
// Consultants
const AllConsultants = React.lazy(() => import("./pages/AllConsultants"))
const CreateConsultant = React.lazy(() => import("./pages/consultants/CreateConsultant"))
// Garages
const AllGarages = React.lazy(() => import("./pages/AllGarages"))
const CreateGarage = React.lazy(() => import("./pages/garages/CreateGarage"))
// Externals
const HomeCustomerInput = React.lazy(() => import("./pages/external/HomeCustomerInput"))
const HomeCustomerInvitedByStaff = React.lazy(
  () => import("./pages/external/HomeCustomerInvitedByStaff")
)
const ConsultantRequestAssessment = React.lazy(
  () => import("./pages/external/ConsultantRequestAssessment")
)
const CustomerTrackClaimsStatus = React.lazy(
  () => import("./pages/external/CustomerTrackClaimsStatus")
)
const DischargeVoucherDetails = React.lazy(() => import("./pages/external/DischargeVoucherDetails"))
const DischargeVoucherOfflineSignature = React.lazy(
  () => import("./pages/external/dischargeVoucher/OfflineSignature")
)
const DischargeVoucherOnlineSignature = React.lazy(
  () => import("./pages/external/dischargeVoucher/OnlineSignature")
)
const DischargeVoucherOnlineWitnessSignature = React.lazy(
  () => import("./pages/external/dischargeVoucher/OnlineWitnessSignature")
)
// const HomeCorporateCustomer = React.lazy(() => import("./pages/HomeCorporateCustomer"))
const HomeIntermediarieCustomer = React.lazy(() => import("./pages/HomeIntermediarieCustomer"))
const HomeClaimsProcessing = React.lazy(() => import("./pages/HomeClaimsProcessing"))
const Http404 = React.lazy(() => import("./pages/errors/Http404"))

function App() {
  return (
    <React.Suspense fallback={<PreloaderApp />}>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route exact path="/test-page" element={<TestPage />} />
        <Route exact path="/signin" element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route exact path="/user/activate/:email/:authCode" element={<AccountValidation />} />
        <Route exact path="/generate-activation-link" element={<GenerateActivationLink />} />
        <Route exact path="/account/password/reset" element={<RequestForUpdatePassword />} />
        <Route exact path="/account/password/update" element={<UpdatePasswordAccount />} />
        <Route exact path="/home-customer" element={<HomeCustomerInput />} />
        <Route
          exact
          path="/home-customer/discharge-voucher/:claimId"
          element={<DischargeVoucherDetails />}
        />
        <Route
          exact
          path="/home-customer/discharge-voucher/:claimId/offline"
          element={<DischargeVoucherOfflineSignature />}
        />
        <Route
          exact
          path="/home-customer/discharge-voucher/:claimId/online"
          element={<DischargeVoucherOnlineSignature />}
        />
        <Route
          exact
          path="/home-witness/discharge-voucher/:claimId/online"
          element={<DischargeVoucherOnlineWitnessSignature />}
        />
        <Route
          exact
          path="/submitclaims/:claimId/intermediary/:intermediarieId"
          element={<HomeCustomerInvitedByStaff />}
        />
        <Route
          exact
          path="/external/consultant/:consultantId/claim/:claimId/assessment"
          element={<ConsultantRequestAssessment />}
        />
        <Route
          exact
          path="/external/customer/:customerId/claim/:claimId/view"
          element={<CustomerTrackClaimsStatus />}
        />

        {/* WE WANT TO PROTECT THESE ROUTES */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route element={<DashboardLayout />}>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/support" element={<Support />} />
              {/* <Route exact path="/dashboard" element={<HomeDashboard />} /> */}
              <Route exact path="/home-corporate" element={<HomeIntermediarieCustomer />} />
              <Route exact path="/home-processing" element={<HomeClaimsProcessing />} />
              {/* Claims Route */}
              <Route exact path="/all-claims" element={<AllClaims />} />
              <Route exact path="/claims/pending" element={<AllPendingClaims />} />
              <Route exact path="/claims/on-hold" element={<AllOnHoldClaims />} />
              <Route exact path="/claims/rejected" element={<AllRejectedClaims />} />
              <Route exact path="/claims/declined" element={<AllDeclinedClaims />} />
              <Route exact path="/claims/completed" element={<AllCompletedClaims />} />
              <Route exact path="/all-notassign-claims" element={<AllNotAssignClaims />} />
              <Route exact path="/all-assign-claims" element={<AllAssignClaims />} />
              <Route exact path="/claimstatus" element={<ViewClaimStatus />} />
              <Route exact path="/claims-details/:claimId" element={<ViewClaimDetails />} />
              <Route exact path="/log/claim/:claimId" element={<ViewClaimLogs />} />
              <Route exact path="/claims/customer/:customerId" element={<ViewCustomerDetails />} />
              <Route
                exact
                path="/claims/customer/policy/:policyId"
                element={<ViewCustomerPolicyDetails />}
              />
              <Route exact path="/claims/assign/:claimId" element={<FirstAssignClaimToUser />} />
              <Route exact path="/submit-uploadscan" element={<SubmitUploadScanClaims />} />
              <Route exact path="/submit-onportail" element={<SubmitOnPortailClaims />} />
              <Route exact path="/submitclaims-staff" element={<SubmitClaimsStaff />} />
              {/* <Route exact path="/claim/chat" element={<ViewChatClaimDetails />} /> */}
              <Route
                exact
                path="/finance/claims-details/:claimId"
                element={<ViewClaimDetailsFromFinance />}
              />
              <Route exact path="/claims/payments-request" element={<AllPaymentsRequest />} />
              <Route
                exact
                path="/claims/waiting-approval"
                element={<AllClaimsWaitingForApproval />}
              />
              <Route
                exact
                path="/claim/update/recommendation/:claimId"
                element={<RecommandationStageUpdate />}
              />
              <Route exact path="/supplementary/claims" element={<AllClaimsSupplementary />} />
              <Route
                exact
                path="/claim/update/uploadscan/:claimId"
                element={<UpdateUploadScanClaims />}
              />
              <Route
                exact
                path="/claim/update/onportal/:claimId"
                element={<UpdateOnPortalClaims />}
              />
              <Route exact path="/claim/files/update/:claimId" element={<UpdateFilesClaims />} />
              {/* Discharge Voucher */}
              <Route
                exact
                path="/claim/discharge-voucher/:claimId"
                element={<InHouseDischargeVoucherDetails />}
              />
              <Route
                exact
                path="/claim/discharge-voucher/:claimId/offline"
                element={<InHouseDischargeVoucherOfflineSignature />}
              />
              <Route
                exact
                path="/claim/discharge-voucher/:claimId/online"
                element={<InHouseDischargeVoucherOnlineSignature />}
              />
              {/* Archive */}
              <Route exact path="/archive" element={<ArchiveClaims />} />
              {/* Users Route */}
              <Route exact path="/users" element={<AllUsers />} />
              <Route exact path="/create-account" element={<CreateUser />} />
              <Route exact path="/attach-branch/:userId" element={<AttachBrancheToUser />} />
              <Route exact path="/user/:userId" element={<UserViewDetails />} />
              <Route exact path="/user-right/:userId" element={<UpdateUserRight />} />
              <Route exact path="/user/update/role/:userId" element={<UpdateRoleUser />} />
              <Route exact path="/user/update/details/:userId" element={<UpdateDetailsUser />} />
              <Route exact path="/update-user-password/:userId" element={<UpdateUserPassword />} />
              <Route exact path="/settings/profile" element={<GeneralProfileSettings />} />
              <Route exact path="/settings/privacy" element={<UpdateMyUserPassword />} />
              <Route exact path="/settings/update/profile" element={<UpdateProfileDetails />} />
              {/* Branches Route  */}
              <Route exact path="/branches" element={<AllBranches />} />
              <Route exact path="/create-branche" element={<CreateBranche />} />
              {/* Branches Route  */}
              <Route exact path="/banks" element={<AllBanks />} />
              <Route exact path="/create-bank" element={<CreateBank />} />
              {/* Intermediaries Route */}
              <Route exact path="/intermediaries" element={<AllIntermediaries />} />
              <Route exact path="/create-intermediary" element={<CreateIntermediarie />} />
              <Route exact path="/intermediary/:intermediarieId" element={<ViewIntermediarie />} />
              <Route
                exact
                path="/intermediary/create-user/:intermediarieId"
                element={<CreateUserIntermediarie />}
              />
              <Route
                exact
                path="/intermediary/user/:userId"
                element={<IntermediaryViewUserDetails />}
              />
              {/* Workgroups Route */}
              <Route exact path="/workgroups" element={<AllMainWorkgroups />} />
              <Route
                exact
                path="/workgroup/:workgroupId/branches"
                element={<AllWorkgroupBranches />}
              />
              <Route
                exact
                path="/workgroup/:workgroupId/users"
                element={<AllUsersOfWorkgroupBranche />}
              />
              <Route
                exact
                path="/create-workgroup/:workgroupId"
                element={<CreateWorkgroupBranche />}
              />
              <Route exact path="/attach-usertoworkgroup" element={<AttachWorkgroupToUser />} />
              {/* Consultants Route */}
              <Route exact path="/consultants" element={<AllConsultants />} />
              <Route exact path="/create-consultant" element={<CreateConsultant />} />
              {/* Garages Route */}
              <Route exact path="/garages" element={<AllGarages />} />
              <Route exact path="/create-garage" element={<CreateGarage />} />
            </Route>
            <Route exact path="/chat/claim/:claimId" element={<ViewChatClaimDetails />} />
          </Route>
        </Route>

        {/* Error Route */}
        <Route path="*" element={<Http404 />} />
      </Routes>
    </React.Suspense>
  )
}

export default App
