import {
  LOADING_CLAIM,
  STOP_LOADING_CLAIM,
  CLAIM_ERROR,
  SET_CLAIMS,
  SET_RECENT_CLAIMS,
  SET_SEARCH_CLAIMS,
  SET_NOTASSIGN_CLAIMS,
  SET_ASSIGN_CLAIMS,
  SET_CLAIMS_CUTOMER,
  SET_CLAIM,
  SET_CLAIM_LOGS,
  DELETE_CLAIM,
  CLEAR_ERRORS_CLAIM,
  INITIAL_STATE_CLAIM,
  INITIAL_ERROR_CLAIM,
  CLAIM_SUCCESS,
  INITIAL_SUCCESS_CLAIM
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all claims belong to customer
const initialState = {
  claims: [],
  recentClaims: [],
  searchClaims: [],
  claim: {},
  logs: [],
  notAssignClaims: [],
  assignClaims: [],
  customers: [],
  loading: false,
  errors: null,
  success: null
}

const ClaimReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CLAIM:
      return { ...state, loading: true }
    case STOP_LOADING_CLAIM:
      return { ...state, loading: false }
    case CLAIM_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_CLAIMS:
      return { ...state, claims: action.payload, loading: false }
    case SET_RECENT_CLAIMS:
      return { ...state, recentClaims: action.payload, loading: false }
    case SET_SEARCH_CLAIMS:
      return { ...state, searchClaims: action.payload, loading: false }
    case SET_NOTASSIGN_CLAIMS:
      return { ...state, notAssignClaims: action.payload, loading: false }
    case SET_ASSIGN_CLAIMS:
      return { ...state, assignClaims: action.payload, loading: false }
    case SET_CLAIMS_CUTOMER:
      return { ...state, customers: action.payload, loading: false }
    case SET_CLAIM:
      return { ...state, claim: action.payload, loading: false }
    case SET_CLAIM_LOGS:
      return { ...state, logs: action.payload, loading: false }
    case DELETE_CLAIM:
      return {
        ...state,
        claims: state.claims.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_CLAIM:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_CLAIM:
      return { ...state, loading: false, errors: null }
    case CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_CLAIM:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_CLAIM:
      return initialState
    default:
      return state
  }
}

export default ClaimReducer
