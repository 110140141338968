import {
  LOADING_DISCHARGE_VOUCHER,
  STOP_LOADING_DISCHARGE_VOUCHER,
  DISCHARGE_VOUCHER_ERROR,
  SET_DISCHARGE_VOUCHERS,
  SET_DISCHARGE_VOUCHER,
  DELETE_DISCHARGE_VOUCHER,
  CLEAR_ERRORS_DISCHARGE_VOUCHER,
  INITIAL_STATE_DISCHARGE_VOUCHER,
  INITIAL_ERROR_DISCHARGE_VOUCHER,
  DISCHARGE_VOUCHER_SUCCESS,
  INITIAL_SUCCESS_DISCHARGE_VOUCHER
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all dischargeVouchers belong to customer
const initialState = {
  dischargeVouchers: [],
  dischargeVoucher: {},
  loading: false,
  errors: null,
  success: null
}

const DischargeVoucherReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DISCHARGE_VOUCHER:
      return { ...state, loading: true }
    case STOP_LOADING_DISCHARGE_VOUCHER:
      return { ...state, loading: false }
    case DISCHARGE_VOUCHER_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_DISCHARGE_VOUCHERS:
      return { ...state, dischargeVouchers: action.payload, loading: false }
    case SET_DISCHARGE_VOUCHER:
      return { ...state, dischargeVoucher: action.payload, loading: false }
    case DELETE_DISCHARGE_VOUCHER:
      return {
        ...state,
        dischargeVouchers: state.dischargeVouchers.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_DISCHARGE_VOUCHER:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_DISCHARGE_VOUCHER:
      return { ...state, loading: false, errors: null }
    case DISCHARGE_VOUCHER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_DISCHARGE_VOUCHER:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_DISCHARGE_VOUCHER:
      return initialState
    default:
      return state
  }
}

export default DischargeVoucherReducer
