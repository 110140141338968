import { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import useRefreshToken from "../../../hooks/useRefreshToken"
import PreloaderApp from "../../../utils/PreloaderApp"
// Redux Stuff
import { connect } from "react-redux"

const PersistLogin = ({ user }) => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  // console.log("PersistLogin User----", user?.token, user.isAuthenticated)

  useEffect(() => {
    let isMounted = true

    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (err) {
        console.error(err)
      } finally {
        isMounted && setIsLoading(false)
      }
    }

    // Avoids unwanted call to verifyRefreshToken
    !user?.token ? verifyRefreshToken() : setIsLoading(false)

    return () => (isMounted = false)
  }, [])

  useEffect(() => {
    // console.log(`isLoading: ${isLoading}`)
    // console.log(`aT: ${JSON.stringify(user?.token)}`)
  }, [isLoading])

  return <>{isLoading ? <PreloaderApp /> : <Outlet />}</>
}

const mapStateToProps = (state) => ({
  user: state.user
})

export default connect(mapStateToProps)(PersistLogin)
