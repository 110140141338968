import {
  LOADING_GARAGE,
  STOP_LOADING_GARAGE,
  GARAGE_ERROR,
  SET_GARAGES,
  SET_GARAGE,
  DELETE_GARAGE,
  CLEAR_ERRORS_GARAGE,
  INITIAL_STATE_GARAGE,
  INITIAL_ERROR_GARAGE
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  garages: [],
  garage: {},
  loading: false,
  errors: null
}

const GarageReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_GARAGE:
      return { ...state, loading: true }
    case STOP_LOADING_GARAGE:
      return { ...state, loading: false }
    case GARAGE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_GARAGES:
      return { ...state, garages: action.payload, loading: false }
    case SET_GARAGE:
      return { ...state, garage: action.payload, loading: false }
    case DELETE_GARAGE:
      return {
        ...state,
        garages: state.garages.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_GARAGE:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_GARAGE:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_GARAGE:
      return initialState
    default:
      return state
  }
}

export default GarageReducer
