import {
  LOADING_VERIFICATION,
  STOP_LOADING_VERIFICATION,
  VERIFICATION_ERROR,
  SET_VERIFICATIONS,
  SET_VERIFICATION,
  DELETE_VERIFICATION,
  CLEAR_ERRORS_VERIFICATION,
  INITIAL_STATE_VERIFICATION,
  INITIAL_ERROR_VERIFICATION,
  VERIFICATION_SUCCESS,
  INITIAL_SUCCESS_VERIFICATION
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all verifications belong to customer
const initialState = {
  verifications: [],
  verification: {},
  loading: false,
  errors: null,
  success: null
}

const VerificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_VERIFICATION:
      return { ...state, loading: true }
    case STOP_LOADING_VERIFICATION:
      return { ...state, loading: false }
    case VERIFICATION_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_VERIFICATIONS:
      return { ...state, verifications: action.payload, loading: false }
    case SET_VERIFICATION:
      return { ...state, verification: action.payload, loading: false }
    case DELETE_VERIFICATION:
      return {
        ...state,
        verifications: state.verifications.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_VERIFICATION:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_VERIFICATION:
      return { ...state, loading: false, errors: null }
    case VERIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_VERIFICATION:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_VERIFICATION:
      return initialState
    default:
      return state
  }
}

export default VerificationReducer
