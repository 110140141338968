import {
  LOADING_POLICY,
  STOP_LOADING_POLICY,
  POLICY_ERROR,
  SET_CUSTOMER_POLICIES,
  SET_POLICIES,
  SET_POLICY,
  DELETE_POLICY,
  CLEAR_ERRORS_POLICY,
  INITIAL_STATE_POLICY,
  INITIAL_ERROR_POLICY
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  policies: [],
  customerPolicies: [],
  policy: {},
  loading: false,
  errors: null
}

const PolicyReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_POLICY:
      return { ...state, loading: true }
    case STOP_LOADING_POLICY:
      return { ...state, loading: false }
    case POLICY_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_POLICIES:
      return { ...state, policies: action.payload, loading: false }
    case SET_CUSTOMER_POLICIES:
      return { ...state, customerPolicies: action.payload, loading: false }
    case SET_POLICY:
      return { ...state, policy: action.payload, loading: false }
    case DELETE_POLICY:
      return {
        ...state,
        policies: state.policies.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_POLICY:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_POLICY:
      return { ...state, loading: false, errors: null }
    case INITIAL_STATE_POLICY:
      return initialState
    default:
      return state
  }
}

export default PolicyReducer
