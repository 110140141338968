import { legacy_createStore as createStore, combineReducers, applyMiddleware } from "redux"
import thunk from "redux-thunk"
// import logger from 'redux-logger';
// Redux Persist
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
// Reducers
import UserReducer from "./reducers/UserReducer"
import ExternalReducer from "./reducers/ExternalReducer"
import BrancheReducer from "./reducers/BrancheReducer"
import IntermediarieReducer from "./reducers/IntermediarieReducer"
import ConfirmContactReducer from "./reducers/ConfirmContactReducer"
import ClaimReducer from "./reducers/ClaimReducer"
import WorkgroupReducer from "./reducers/WorkgroupReducer"
import ProcessingReducer from "./reducers/ProcessingReducer"
import AnalyticsReducer from "./reducers/AnalyticsReducer"
import RightReducer from "./reducers/RightReducer"
import VerificationReducer from "./reducers/VerificationReducer"
import InvestigationReducer from "./reducers/InvestigationReducer"
import AdjustmentReducer from "./reducers/AdjustmentReducer"
import ApprovalReducer from "./reducers/ApprovalReducer"
import DischargeVoucherReducer from "./reducers/DischargeVoucherReducer"
import RecommendationReducer from "./reducers/RecommendationReducer"
import FinanceReducer from "./reducers/FinanceReducer"
import ConsultantReducer from "./reducers/ConsultantReducer"
import MessageReducer from "./reducers/MessageReducer"
import GarageReducer from "./reducers/GarageReducer"
import PolicyReducer from "./reducers/PolicyReducer"
import FinanceMeetCriteriaReducer from "./reducers/FinanceMeetCriteriaReducer"
import FinanceCheckTreasuryReducer from "./reducers/FinanceCheckTreasuryReducer"
import FinancePaymentReducer from "./reducers/FinancePaymentReducer"

const persistConfig = {
  key: "root",
  storage
}
// Spread and ternary operators middleware
// let middleware = [applyMiddleware(thunk)];
// Disable redux-logger in production build
// if (process.env.NODE_ENV !== "production") {
//   middleware = [
//     ...middleware,
//     ...(window.__REDUX_DEVTOOLS_EXTENSION__
//       ? [window.__REDUX_DEVTOOLS_EXTENSION__()]
//       : [])
//   ];
// }
const rootReducer = combineReducers({
  user: UserReducer,
  external: ExternalReducer,
  brancheData: BrancheReducer,
  intermediarieData: IntermediarieReducer,
  confirmContact: ConfirmContactReducer,
  claimData: ClaimReducer,
  workgroupData: WorkgroupReducer,
  processingData: ProcessingReducer,
  analyticsData: AnalyticsReducer,
  rightData: RightReducer,
  verificationData: VerificationReducer,
  investigationData: InvestigationReducer,
  adjustmentData: AdjustmentReducer,
  approvalData: ApprovalReducer,
  dischargeVoucherData: DischargeVoucherReducer,
  recommendationData: RecommendationReducer,
  financeData: FinanceReducer,
  consultantData: ConsultantReducer,
  messageData: MessageReducer,
  garageData: GarageReducer,
  policyData: PolicyReducer,
  finMeetCriteriaData: FinanceMeetCriteriaReducer,
  finCheckTreasuryData: FinanceCheckTreasuryReducer,
  finPaymentData: FinancePaymentReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(thunk))
let persistor = persistStore(store)

export { persistor, store }
