import {
  LOADING_CONFIRMCONTACT,
  STOP_LOADING_CONFIRMCONTACT,
  CONFIRMCONTACT_ERROR,
  VALIDATE_CONFIRMCONTACT,
  SET_CONFIRMCONTACT,
  CLEAR_ERRORS_CONFIRMCONTACT,
  INITIAL_STATE_CONFIRMCONTACT,
  INITIAL_ERROR_CONFIRMCONTACT,
  CONFIRMCONTACT_SUCCESS,
  INITIAL_SUCCESS_CONFIRMCONTACT
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  contact: null,
  confirmContactId: null,
  validate: false,
  loading: false,
  errors: null,
  success: null
}

const ConfirmContactReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_CONFIRMCONTACT:
      return { ...state, loading: true }
    case STOP_LOADING_CONFIRMCONTACT:
      return { ...state, loading: false }
    case CONFIRMCONTACT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_CONFIRMCONTACT:
      return {
        ...state,
        contact: action.payload.result.contact,
        confirmContactId: action.payload.result.id,
        success: action.payload.message,
        loading: false
      }
    case VALIDATE_CONFIRMCONTACT:
      return {
        ...state,
        validate: true,
        loading: false
      }
    case INITIAL_ERROR_CONFIRMCONTACT:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_CONFIRMCONTACT:
      return { ...state, loading: false, errors: null }
    case CONFIRMCONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_CONFIRMCONTACT:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_CONFIRMCONTACT:
      return initialState
    default:
      return state
  }
}

export default ConfirmContactReducer
