import React from "react"
import { Link } from "react-router-dom"
// MUI Stuff
import Box from "@mui/material/Box"
import Avatar from "@mui/material/Avatar"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Tooltip from "@mui/material/Tooltip"
// Icons
import PersonRoundedIcon from "@mui/icons-material/PersonRounded"
// import Settings from "@mui/icons-material/Settings"
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined"
// import Logout from "@mui/icons-material/Logout"
// Components
// import LogoutAccountMenu from "./LogoutAccountMenu"
// Redux Stuff
import { connect } from "react-redux"

function AccountMenu(props) {
  const { userData } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>U</Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1
            },
            "&:before": {
              // eslint-disable-next-line prettier/prettier, quotes
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0
            }
          }
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem>
          <Typography variant="inherit">Welcome {userData && userData.firstname}</Typography>
        </MenuItem>
        <MenuItem component={Link} to={"/settings/profile"}>
          <ListItemIcon>
            <PersonRoundedIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <Divider />
        <MenuItem component={Link} to={"/settings/privacy"}>
          <ListItemIcon>
            <HttpsOutlinedIcon fontSize="small" />
          </ListItemIcon>
          Privacy settings
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => ({
  userData: state.user.credentials
})

export default connect(mapStateToProps)(AccountMenu)
