import {
  LOADING_ADJUSTMENT,
  STOP_LOADING_ADJUSTMENT,
  ADJUSTMENT_ERROR,
  SET_ADJUSTMENTS,
  SET_ADJUSTMENT,
  DELETE_ADJUSTMENT,
  CLEAR_ERRORS_ADJUSTMENT,
  INITIAL_STATE_ADJUSTMENT,
  INITIAL_ERROR_ADJUSTMENT,
  ADJUSTMENT_SUCCESS,
  INITIAL_SUCCESS_ADJUSTMENT
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all adjustments belong to customer
const initialState = {
  adjustments: [],
  adjustment: {},
  loading: false,
  errors: null,
  success: null
}

const AdjustmentReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_ADJUSTMENT:
      return { ...state, loading: true }
    case STOP_LOADING_ADJUSTMENT:
      return { ...state, loading: false }
    case ADJUSTMENT_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_ADJUSTMENTS:
      return { ...state, adjustments: action.payload, loading: false }
    case SET_ADJUSTMENT:
      return { ...state, adjustment: action.payload, loading: false }
    case DELETE_ADJUSTMENT:
      return {
        ...state,
        adjustments: state.adjustments.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_ADJUSTMENT:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_ADJUSTMENT:
      return { ...state, loading: false, errors: null }
    case ADJUSTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_ADJUSTMENT:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_ADJUSTMENT:
      return initialState
    default:
      return state
  }
}

export default AdjustmentReducer
