import {
  LOADING_EXTERNAL,
  LOADING_EXTERNAL_REQUEST,
  STOP_LOADING_EXTERNAL,
  STOP_LOADING_EXTERNAL_REQUEST,
  EXTERNAL_ERROR,
  EXTERNAL_ERROR_REQUEST,
  SET_INVESTIGATION_EXTERNAL,
  SET_CONSULTANT_EXTERNAL,
  SET_CLAIM_EXTERNAL,
  CLEAR_ERRORS_EXTERNAL,
  INITIAL_STATE_EXTERNAL,
  INITIAL_ERROR_EXTERNAL,
  EXTERNAL_SUCCESS,
  EXTERNAL_SUCCESS_REQUEST,
  INITIAL_SUCCESS_EXTERNAL
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
// customers is all investigations belong to customer
const initialState = {
  consultant: {},
  investigation: {},
  claim: {},
  loading: false,
  loadingRequest: false,
  errors: null,
  success: null
}

const ExternalReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_EXTERNAL:
      return { ...state, loading: true }
    case LOADING_EXTERNAL_REQUEST:
      return { ...state, loadingRequest: true }
    case STOP_LOADING_EXTERNAL:
      return { ...state, loading: false }
    case STOP_LOADING_EXTERNAL_REQUEST:
      return { ...state, loadingRequest: false }
    case EXTERNAL_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case EXTERNAL_ERROR_REQUEST:
      return {
        ...state,
        loadingRequest: false,
        errors: action.payload
      }
    case SET_INVESTIGATION_EXTERNAL:
      return { ...state, investigation: action.payload, loading: false }
    case SET_CONSULTANT_EXTERNAL:
      return { ...state, consultant: action.payload, loading: false }
    case SET_CLAIM_EXTERNAL:
      return { ...state, claim: action.payload, loading: false }
    case INITIAL_ERROR_EXTERNAL:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_EXTERNAL:
      return { ...state, loadingRequest: false, loading: false, errors: null }
    case EXTERNAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case EXTERNAL_SUCCESS_REQUEST:
      return {
        ...state,
        loadingRequest: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_EXTERNAL:
      return {
        ...state,
        success: null
      }
    case INITIAL_STATE_EXTERNAL:
      return initialState
    default:
      return state
  }
}

export default ExternalReducer
