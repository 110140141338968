export default {
  typography: {
    fontFamily: "'Montserrat', 'Roboto', sans-serif"
  },
  palette: {
    primary: {
      main: "#442359",
      contrastText: "#fff"
    },
    secondary: {
      main: "#991232",
      contrastText: "#fff"
    },
    warning: {
      main: "#ed6c02",
      contrastText: "#fff"
    },
    background: {
      default: "#f5f5f5"
    }
  },
  // Default style components
  components: {
    // Default style for button
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 50
        }
      }
    },
    // Default style for input
    MuiOutlinedInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 50
        }
      }
    }
  },

  // the object to be spread
  spreadThis: {
    pBottom0: {
      paddingBottom: "0 !important"
    },
    pTop0: {
      paddingTop: "0 !important"
    },
    pd8: {
      padding: 8
    },
    pd9: {
      padding: 9
    },
    mTop5: {
      marginTop: 5
    },
    mBottom5: {
      marginBottom: 5
    },
    mTop10: {
      marginTop: 10
    },
    mBottom10: {
      marginBottom: 10
    },
    mTop20: {
      marginTop: 20
    },
    mBottom20: {
      marginBottom: 20
    },
    mTop25: {
      marginTop: 25
    },
    mBottom25: {
      marginBottom: 25
    },
    pTop15: {
      paddingTop: 15
    },
    pTop10: {
      paddingTop: 10
    },
    pBottom20: {
      paddingBottom: 20
    },
    pBottom10: {
      paddingBottom: 10
    },
    textCenter: {
      textAlign: "center"
    },
    textLeft: {
      textAlign: "left"
    },
    customError: {
      color: "red",
      fontSize: "0.8rem",
      marginTop: 10
    },
    invisibleSeparator: {
      border: "none",
      margin: 4
    },
    visibleSeparator: {
      width: "100%",
      borderBottom: "1px solid rgba(0,0,0,0.1)",
      marginBottom: 20
    },
    bold: {
      fontWeight: 600
    }
  }
}
