import React from "react"
import { Link } from "react-router-dom"
// MUI Stuff
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import ListItemButton from "@mui/material/ListItemButton"
// Icons
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined"
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined"
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined"
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined"
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined"
import HomeWorkIcon from "@mui/icons-material/HomeWork"
import FilePresentIcon from "@mui/icons-material/FilePresent"
import WorkIcon from "@mui/icons-material/Work"

export const mainListItems = (
  <div>
    <ListItemButton component={Link} to="/dashboard">
      <ListItemIcon>
        <HomeOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton component={Link} to="/reports">
      <ListItemIcon>
        <AssessmentOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="Reports" />
    </ListItemButton>
    <ListItemButton component={Link} to="/settings">
      <ListItemIcon>
        <SettingsOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
    <ListItemButton component={Link} to="/search">
      <ListItemIcon>
        <SearchOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="Search" />
    </ListItemButton>
    <ListItemButton component={Link} to="/users">
      <ListItemIcon>
        <GroupOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="List Users" />
    </ListItemButton>
    <ListItemButton component={Link} to="/branches">
      <ListItemIcon>
        <ApartmentOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="List Branches" />
    </ListItemButton>
    <ListItemButton component={Link} to="/corporates">
      <ListItemIcon>
        <HomeWorkIcon />
      </ListItemIcon>
      <ListItemText primary="List Corporates" />
    </ListItemButton>
    <ListItemButton component={Link} to="/workgroups">
      <ListItemIcon>
        <WorkIcon />
      </ListItemIcon>
      <ListItemText primary="List Workgroups" />
    </ListItemButton>
    <ListItemButton component={Link} to="/all-notassign-claims">
      <ListItemIcon>
        <FilePresentIcon />
      </ListItemIcon>
      <ListItemText primary="Not assign Claims" />
    </ListItemButton>
  </div>
)

export const secondaryListItems = (
  <>
    <ListItemButton style={{ marginTop: 50 }} component={Link} to="/support">
      <ListItemIcon>
        <SupportAgentOutlinedIcon />
      </ListItemIcon>
      <ListItemText primary="Support" />
    </ListItemButton>
    {/* <ListItem button component={Link} to="/">
      <ListItemText primary="Claims Inputter Home" />
    </ListItem>
    <ListItem button component={Link} to="/home-customer">
      <ListItemText primary="Customer Input Home" />
    </ListItem>
    <ListItem button component={Link} to="/home-corporate">
      <ListItemText primary="Corporate Customer Home" />
    </ListItem>
    <ListItem button component={Link} to="/home-processing">
      <ListItemText primary="Claims Processing Home" />
    </ListItem>
    <ListItem button component={Link} to="/">
      <ListItemText primary="Finance Home" />
    </ListItem> */}
  </>
)
