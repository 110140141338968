import {
  LOADING_INTERMEDIARIE,
  STOP_LOADING_INTERMEDIARIE,
  INTERMEDIARIE_ERROR,
  SET_INTERMEDIARIES,
  SET_INTERMEDIARIE,
  SET_INTERMEDIARIE_USERS,
  SET_INTERMEDIARIE_USER,
  DELETE_INTERMEDIARIE,
  CLEAR_ERRORS_INTERMEDIARIE,
  INITIAL_STATE_INTERMEDIARIE,
  INITIAL_ERROR_INTERMEDIARIE,
  INTERMEDIARIE_SUCCESS,
  INITIAL_SUCCESS_INTERMEDIARIE,
  CLEAR_SUCCESS_INTERMEDIARIE
} from "../Types"

// Adding request field to track dispatch when the resquest have been succes
const initialState = {
  users: [],
  user: {},
  intermediaries: [],
  intermediarie: {},
  loading: false,
  errors: null,
  success: null
}

const IntermediarieReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOADING_INTERMEDIARIE:
      return { ...state, loading: true }
    case STOP_LOADING_INTERMEDIARIE:
      return { ...state, loading: false }
    case INTERMEDIARIE_ERROR:
      return {
        ...state,
        loading: false,
        errors: action.payload
      }
    case SET_INTERMEDIARIES:
      return { ...state, intermediaries: action.payload, loading: false }
    case SET_INTERMEDIARIE_USERS:
      return { ...state, users: action.payload, loading: false }
    case SET_INTERMEDIARIE_USER:
      return { ...state, user: action.payload, loading: false }
    case SET_INTERMEDIARIE:
      return { ...state, intermediarie: action.payload, loading: false }
    case DELETE_INTERMEDIARIE:
      return {
        ...state,
        intermediaries: state.intermediaries.filter((item) => item.id !== action.payload),
        loading: false
      }
    case INITIAL_ERROR_INTERMEDIARIE:
      return {
        ...state,
        errors: null
      }
    case CLEAR_ERRORS_INTERMEDIARIE:
      return { ...state, loading: false, errors: null }
    case INTERMEDIARIE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload
      }
    case INITIAL_SUCCESS_INTERMEDIARIE:
      return {
        ...state,
        success: null
      }
    case CLEAR_SUCCESS_INTERMEDIARIE:
      return {
        ...state,
        loading: false,
        success: null
      }
    case INITIAL_STATE_INTERMEDIARIE:
      return initialState
    default:
      return state
  }
}

export default IntermediarieReducer
